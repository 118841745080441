import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { FastAnswer } from 'modules/domain/fast-answers/model';

import { ChatInputModelState, CopilotData } from './types';
import { setInputTextState } from './helpers';

type SetFastAnswerSelectedAction = PayloadAction<{ message: string; fastAnswer: FastAnswer }>;
type SetInputTextAction = PayloadAction<{ inputText: string; append?: boolean }>;
type SetCopilotDataAction = PayloadAction<{ dataId: string; copilotData: CopilotData }>;
type SetCopilotDataLoadingAction = PayloadAction<{ dataId: string }>;
type SetCopilotTextOptionSelectedAction = PayloadAction<{ data: CopilotData; optionText: string }>;

const initialState: ChatInputModelState = {
  inputFocused: false,
  inputText: '',
  selectedFastAnswer: null,
  copilot: {
    lastUsedData: null,
    data: {},
  },
};

export const chatInputModel = createSlice({
  name: 'chatInput',
  initialState,
  reducers: {
    setCopilotDataLoadingStart: (state, { payload: { dataId } }: SetCopilotDataLoadingAction) => {
      state.copilot.data[dataId] = { loading: true };
    },
    setCopilotDataLoadingFailed: (state, { payload: { dataId } }: SetCopilotDataLoadingAction) => {
      state.copilot.data[dataId] = { loading: false };
    },
    setCopilotData: (state, { payload: { dataId, copilotData } }: SetCopilotDataAction) => {
      state.copilot.data[dataId] = copilotData;
    },
    resetCopilotData: state => {
      state.copilot.data = initialState.copilot.data;
    },
    setCopilotTextOptionSelected: (state, action: SetCopilotTextOptionSelectedAction) => {
      const { optionText, data } = action.payload;
      setInputTextState(state, optionText, { setCopilotOptionEdited: false });

      state.copilot.lastUsedData = data;
    },
    resetInputText: state => {
      state.inputText = initialState.inputText;
      state.selectedFastAnswer = initialState.selectedFastAnswer;
      state.copilot.lastUsedData = initialState.copilot.lastUsedData;
    },
    setInputFocused: (state, { payload }: PayloadAction<boolean>) => {
      state.inputFocused = payload;
    },
    setInputText: (state, { payload: { inputText, append } }: SetInputTextAction) => {
      setInputTextState(state, inputText, { append });
    },
    setFastAnswerSelected: (
      state,
      { payload: { message, fastAnswer } }: SetFastAnswerSelectedAction,
    ) => {
      setInputTextState(state, `${state.inputText} ${message}`.trim());
      state.selectedFastAnswer = { ...fastAnswer };
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getCopilotData: (state, dataId: string) => state.copilot.data[dataId],
    getCopilotLastUsedData: state => state.copilot.lastUsedData,
    getInputText: state => state.inputText,
    getInputFocused: state => state.inputFocused,
    getSelectedFastAnswer: state => state.selectedFastAnswer,
  },
});
