import { ActionWithDialogId } from 'modules/domain/common/types';

import { DialogMessagesModelState, DialogMessagesState } from '../types';

export const updateDialogMessagesState =
  <Action extends ActionWithDialogId = ActionWithDialogId>(
    updateFn: (state: DialogMessagesState, payload: Action['payload']) => void,
  ) =>
  (state: DialogMessagesModelState, { payload }: Action) => {
    const dialogMessagesState = state[payload.dialogId];
    if (!dialogMessagesState) {
      return;
    }

    updateFn(dialogMessagesState, payload);
  };
