import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addLogoutReducer } from 'modules/domain/auth/actions';

import { FastAnswersList, FastAnswersModelState } from './types';

export type SetFastAnswersAction = PayloadAction<{
  data: FastAnswersList;
}>;

const initialState: FastAnswersModelState = {
  data: null,
};

export const fastAnswersModel = createSlice({
  name: 'fastAnswers',
  initialState,
  reducers: {
    setFastAnswers: (state, action: SetFastAnswersAction) => {
      const { data } = action.payload;

      state.data = data;
    },
  },
  extraReducers: addLogoutReducer(() => initialState),
  selectors: {
    getFastAnswers: state => state.data,
  },
});
