import { useCallback } from 'react';

import { parseDialogId } from 'modules/utils';
import { useCopilotAnalytics } from 'modules/domain/chat-input/hooks';

import { SendDialogMessageData } from '../model';

import { useSendDialogMessageBaseApi } from './use-send-dialog-message-base-api';

export const useSendDialogMessageApi = (dialogId: string) => {
  const sendMessageBase = useSendDialogMessageBaseApi(dialogId);
  const { animatorId, attendeeId } = parseDialogId(dialogId);
  const { trackCopilotUsageAndInjectMeta } = useCopilotAnalytics(animatorId, attendeeId);

  const sendDialogMessage = useCallback(
    async (data: SendDialogMessageData) => {
      trackCopilotUsageAndInjectMeta(data);

      return sendMessageBase(data);
    },
    [sendMessageBase, trackCopilotUsageAndInjectMeta],
  );

  return sendDialogMessage;
};
