import { useEffect } from 'react';

import { useChatInputFocused } from 'modules/domain/chat-input/hooks';

export function useOnChatInputFocused(callback: () => void) {
  const chatInputFocused = useChatInputFocused();

  useEffect(() => {
    if (chatInputFocused) {
      callback();
    }
  }, [chatInputFocused, callback]);
}
