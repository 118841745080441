import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setUnansweredUnpaidMessageAnswered as setUnansweredUnpaidMessageAnsweredAction } from 'modules/domain/messages/model';
import { useDialogUnansweredUnpaidState } from 'modules/domain/messages/hooks';

export const useUnansweredUnpaidMessageCheck = (dialogId: string) => {
  const dispatch = useDispatch();
  const { messageLoaded, messageAnswered } = useDialogUnansweredUnpaidState(dialogId) || {};

  const shouldAnswerUnansweredUnpaidMessage = !!messageLoaded && !messageAnswered;

  const setUnansweredUnpaidMessageAnswered = useCallback(() => {
    dispatch(setUnansweredUnpaidMessageAnsweredAction({ dialogId }));
  }, [dialogId, dispatch]);

  return {
    shouldAnswerUnansweredUnpaidMessage,
    setUnansweredUnpaidMessageAnswered,
  };
};
