import { useCallback, useEffect, useRef } from 'react';

import { useConfig } from 'modules/config';
import {
  useDialogAllMessagesLoaded,
  useDialogMessagesCount,
  useLoadDialogMessagesApi,
} from 'modules/domain/messages/hooks';
import { useChatScrollerContext } from 'modules/components/chat/chat-scroller-context';

export const useMessagesNextPageLoader = (dialogId: string) => {
  const messagesCount = useDialogMessagesCount(dialogId);
  const allMessagesLoaded = useDialogAllMessagesLoaded(dialogId);

  const { chatPageSize } = useConfig();
  const { loadDialogMessages } = useLoadDialogMessagesApi(dialogId);
  const { scrollPosition } = useChatScrollerContext();

  const isTopPosition = scrollPosition === 'top';
  const isTopPositionPrev = useRef(isTopPosition);

  const loadNextChatPage = useCallback(() => {
    if (allMessagesLoaded) {
      return;
    }

    loadDialogMessages(chatPageSize, messagesCount);
  }, [allMessagesLoaded, loadDialogMessages, messagesCount, chatPageSize]);

  useEffect(() => {
    if (isTopPosition && !isTopPositionPrev.current) {
      loadNextChatPage();
    }
    isTopPositionPrev.current = isTopPosition;
  }, [loadNextChatPage, isTopPosition]);
};
