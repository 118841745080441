import React from 'react';
import cn from 'classnames';

import { ExtendedTimer } from 'modules/components/extended-timer';
import { RoundButton } from 'modules/components/common/button/round';
import { testId } from 'modules/utils';
import { SearchIcon } from 'modules/components/icon';
import {
  useCurrentDialogId,
  useCurrentDialogUsersIds,
  useDialogSearchModeActive,
} from 'modules/domain/dialog/hooks';
import { useDialogMessagesSearchActivityHandlers } from 'modules/domain/messages-search/hooks';

import { ChatSearchWidget } from './search-widget';
import { ThreeDotsMenu } from './three-dots-menu';
import { EarningsBadges } from './earnings-badges';
import { DialogSearchIndicator } from './dialog-search-indicator';
import styles from './index.module.css';

export function BadgesAndSearch() {
  const currentDialogId = useCurrentDialogId();
  const { animatorId, attendeeId } = useCurrentDialogUsersIds();
  const { messagesSearchActive, startMessagesSearch, resetMessagesSearch } =
    useDialogMessagesSearchActivityHandlers(currentDialogId);
  const dialogSearchModeActive = useDialogSearchModeActive();

  const isDefaultMode = !messagesSearchActive && !dialogSearchModeActive;
  const showSearchBtn = currentDialogId;

  return (
    <div
      className={cn(styles.centerHeader, {
        [styles.messageSearchMode]: messagesSearchActive,
      })}
    >
      {isDefaultMode && (
        <>
          <div className={styles.scales}>
            <EarningsBadges />
            <ExtendedTimer />
          </div>
          <div className={styles.controls}>
            {showSearchBtn && (
              <RoundButton
                {...testId('in-chat-search-button')}
                size="md"
                theme="darker"
                onClick={startMessagesSearch}
              >
                <SearchIcon size={12} />
              </RoundButton>
            )}
            <ThreeDotsMenu />
          </div>
        </>
      )}
      {messagesSearchActive && (
        <ChatSearchWidget dialogId={currentDialogId} onClose={resetMessagesSearch} />
      )}
      {dialogSearchModeActive && (
        <DialogSearchIndicator animatorId={animatorId} attendeeId={attendeeId} />
      )}
    </div>
  );
}
