import { messagesSearchModel } from './model';

const { reducer, reducerPath, actions, selectors } = messagesSearchModel;

export const {
  setMessagesSearchResult,
  resetMessagesSearchResult,
  startMessagesSearch,
  resetMessagesSearch,
  switchSearchedMessageIndex,
} = actions;
export const { getMessagesSearchState, getMessagesSearchActive } = selectors;
export { reducer, reducerPath as namespace };

export type {
  MessagesSearchModelState,
  SearchedDialogMessagesState,
  SearchMessagesDirection,
  SearchedDialogMessage,
} from './types';
export { mapSearchedDialogMessagesDto } from './adapter';
