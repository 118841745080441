import { useMemo } from 'react';

import { DialogMessage } from 'modules/domain/messages/model';
import { useChatScrollerContext } from 'modules/components/chat/chat-scroller-context';

export const useFirstInvisibleMessageTimestamp = (messages: DialogMessage[]) => {
  const { firstInvisibleMessageId } = useChatScrollerContext();

  const firstInvisibleMessageTimestamp = useMemo(() => {
    const firstInvisibleMessage = messages.find(message => message.id === firstInvisibleMessageId);

    return firstInvisibleMessage?.timestamp;
  }, [messages, firstInvisibleMessageId]);

  return firstInvisibleMessageTimestamp;
};
