import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikErrors } from 'formik';

import { Button } from 'modules/components/common/button/base';
import { RadioInput } from 'modules/components/common/radio-input';
import {
  ClientComplaints,
  AnimatorComplaints,
  ComplaintReportModalData,
} from 'modules/domain/complaint/types';
import { MessageInput } from 'modules/components/common/message-input';
import { useComplaintApi } from 'modules/domain/complaint/hooks';
import { useActiveModalData, useModalActions } from 'modules/domain/modals/hooks';
import { testId, toast } from 'modules/utils';

import { ComplaintsOnAnimator, ComplaintsOnClient } from './config';
import styles from './index.module.scss';

export type FormValues = {
  complaint: ClientComplaints | AnimatorComplaints | undefined;
  userText: string;
};

const validate = ({ complaint, userText }: Partial<FormValues>) => {
  const errors: FormikErrors<FormValues> = {};

  if (!complaint) {
    errors.complaint = 'Field is required';
  }

  if (complaint === 'Other' && !userText) {
    errors.userText = 'Field is required';
  }

  return errors;
};

const initialValues: FormValues = {
  complaint: undefined,
  userText: '',
};

export const SendComplaintForm = () => {
  const { closeModal } = useModalActions();
  const reportData = useActiveModalData<ComplaintReportModalData>();

  const { sendComplaintReport } = useComplaintApi();

  const handleSubmit = async ({ complaint, userText }: FormValues) => {
    if (!complaint) return;

    try {
      await sendComplaintReport({ complaint, userText, ...reportData });

      closeModal();

      toast.showMessage('Your report has been sent.');
    } catch (error) {
      // the actual error is handled inside the sendComplaintReport func.
      toast.showMessage('Failed to send a report');
    }
  };

  const reportComplaints = reportData.outgoing ? ComplaintsOnAnimator : ComplaintsOnClient;

  return (
    <div className={styles.container}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <Form>
            <Field component="div" name="complaint">
              {reportComplaints.map(complaintType => (
                <RadioInput
                  style={{ marginTop: 8 }}
                  key={complaintType}
                  id={complaintType}
                  name="complaint"
                  checked={complaintType === values.complaint}
                  value={complaintType}
                  label={complaintType}
                />
              ))}
              <ErrorMessage name="complaint">
                {msg => <span className={styles.required}>{msg}</span>}
              </ErrorMessage>
            </Field>
            {values.complaint === 'Other' && (
              <Field
                type="text"
                name="userText"
                placeholder="Type your report reason…"
                backgroundTheme="light"
                className={styles.input}
                component={MessageInput}
                rows={4}
              />
            )}
            <div className={styles.row}>
              <Button
                {...testId('report-message-cancel-button')}
                type="button"
                size="md"
                disabled={isSubmitting}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                {...testId('report-message-send-button')}
                type="submit"
                size="md"
                theme="raspberry"
                disabled={isSubmitting}
              >
                Report
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
