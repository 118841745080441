import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { setUserPhotos, mapUserMediaListDto } from '../model';

export const useUserMediaApi = () => {
  const dispatch = useDispatch();
  const { usersMedia: usersMediaApi } = useApi();
  const { logError } = useLogger('useMediaApi');

  const loadUserPhotos = useCallback(
    async (userId: string) => {
      try {
        const dto = await usersMediaApi.getUserPhotos(userId);
        const photos = mapUserMediaListDto(dto);

        dispatch(setUserPhotos({ userId, photos }));
      } catch (error) {
        logError('Failed to get user photos', { error, userId });
      }
    },
    [dispatch, usersMediaApi, logError],
  );

  return {
    loadUserPhotos,
  };
};
