import { DialogMessageMetaDto } from 'modules/api/dto';
import { useDialogAnalytics } from 'modules/domain/dialog/hooks';
import { useSendDialogMessageApi } from 'modules/domain/messages/hooks';
import { useSelectedFastAnswer } from 'modules/domain/chat-input/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { getDialogId } from 'modules/utils';
import { MessageType } from 'modules/domain/messages/model';

import { useUnansweredUnpaidMessageCheck } from './use-unanswered-unpaid-message-check';

const UNANSWERED_UNPAID_MESSAGE_REASON = 'possible topic';

export const useSendTextMessage = (animatorId: string, attendeeId: string) => {
  const dialogId = getDialogId(animatorId, attendeeId);

  const sendDialogMessage = useSendDialogMessageApi(dialogId);
  const { shouldAnswerUnansweredUnpaidMessage, setUnansweredUnpaidMessageAnswered } =
    useUnansweredUnpaidMessageCheck(dialogId);
  const selectedFastAnswer = useSelectedFastAnswer();
  const { trackDialogMessageSendingWithFastAnswer } = useDialogAnalytics();

  const sendTextMessage = (message: string) => {
    const messageMeta: DialogMessageMetaDto = {};

    if (shouldAnswerUnansweredUnpaidMessage) {
      messageMeta.reason = UNANSWERED_UNPAID_MESSAGE_REASON;

      setUnansweredUnpaidMessageAnswered();
    }

    if (selectedFastAnswer) {
      trackDialogMessageSendingWithFastAnswer(attendeeId, message, selectedFastAnswer);
    }

    sendDialogMessage({ text: message, meta: messageMeta, type: MessageType.TEXT }).catch(() => {
      showChatErrorToast(
        `Failed to send a message: "${message}". Please check your connection and try again later`,
      );
    });
  };

  return sendTextMessage;
};
