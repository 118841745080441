import { showChatErrorToast } from 'modules/components/chat/helpers';
import { DialogMessage } from 'modules/domain/messages/model';

import { IndicatorStatus } from './use-typed-message-indicator';
import { useHasForbiddenMessageCheck } from './use-has-forbidden-message-check';

export const useValidateTextMessage = (
  latestMessage: DialogMessage | undefined,
  indicatorStatus: IndicatorStatus,
) => {
  const checkHasForbiddenMessage = useHasForbiddenMessageCheck(latestMessage);

  const validateTextMessage = (message: string): boolean => {
    if (checkHasForbiddenMessage(message)) {
      showChatErrorToast("You can't send this message to the client");
      return false;
    }

    if (indicatorStatus === IndicatorStatus.Bad) {
      showChatErrorToast(
        'Your message length is not appropriate. Please adjust it to send the message.',
        {
          toastId: 'message-length-error-toast',
        },
      );
      return false;
    }

    return true;
  };

  return validateTextMessage;
};
