import React, { useCallback } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { useOnboarding, useUserField } from 'modules/domain/users/hooks';
import { useOperatorId } from 'modules/domain/auth';
import { useModalActions } from 'modules/domain/modals/hooks';
import { ModalNames } from 'modules/domain/modals/model';
import { CalendarModalData } from 'modules/constants';

import { CloseIcon } from './close-icon';
import { moreThanAYearAgo } from './moreThanYearAgo';
import styles from './styles.module.scss';

type Props = {
  timestamp: number;
  attachOnboarding?: boolean;
  onTop?: boolean;
};

export const DayIndicator = (props: Props) => {
  const { timestamp, attachOnboarding, onTop } = props;
  const { finishOnboarding } = useOnboarding();
  const operatorId = useOperatorId();
  const { openModal } = useModalActions();

  const onDayClick = useCallback(() => {
    if (moreThanAYearAgo(timestamp)) {
      return;
    }
    const modalData: CalendarModalData = { timestamp };
    openModal(ModalNames.Calendar, modalData);
    finishOnboarding();
  }, [timestamp, openModal, finishOnboarding]);

  const needOnboarding = useUserField(operatorId, 'needOnboarding');
  const needToShowOnboarding = attachOnboarding && needOnboarding;

  const timeString = moment(timestamp).calendar('', {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'MMM D YYYY',
  });

  return (
    <div
      className={cn(styles.container, {
        [styles.onTop]: onTop,
        [styles.onboarding]: needToShowOnboarding,
      })}
      onClick={onDayClick}
    >
      {timeString}
      {needToShowOnboarding && (
        <div className={styles.onboardingHint} onClick={event => event.stopPropagation()}>
          Click on the dates to open the calendar and jump to a specific date!
          <CloseIcon className={styles.closeButton} onClick={finishOnboarding} />
        </div>
      )}
    </div>
  );
};
