import { createSlice } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import { DialogMessage, DialogMessagesInitState, DialogMessagesModelState } from './types';
import { updateDialogMessagesState, insertDialogMessages } from './helpers';

type InitDialogMessagesStateAction = ActionWithDialogId<{
  messagesInitState: DialogMessagesInitState;
}>;
type InsertDialogMessagesAction = ActionWithDialogId<{
  messages: DialogMessage[];
  allMessagesLoaded: boolean;
}>;
type DialogMessageBaseAction<T = {}> = ActionWithDialogId<{ message: DialogMessage } & T>;
type DialogMessageTagBaseAction<T = {}> = ActionWithDialogId<{ messageTag: string } & T>;
type SetMessageMediaUploadProgressAction = DialogMessageTagBaseAction<{ progress: number }>;

const initialState: DialogMessagesModelState = {};

export const dialogMessagesModel = createSlice({
  name: 'dialogMessages',
  initialState,
  reducers: {
    initDialogMessagesState: (state, action: InitDialogMessagesStateAction) => {
      const { dialogId, messagesInitState } = action.payload;
      state[dialogId] = {
        messages: [],
        messagesMediaUpload: {},
        someMessagesLoaded: false,
        allMessagesLoaded: false,
        ...messagesInitState,
      };
    },
    insertDialogMessages: updateDialogMessagesState<InsertDialogMessagesAction>(
      (messagesState, { messages: newMessages, allMessagesLoaded }) => {
        messagesState.someMessagesLoaded = true;

        if (allMessagesLoaded) {
          messagesState.allMessagesLoaded = allMessagesLoaded;
        }

        if (newMessages.length) {
          insertDialogMessages(messagesState, newMessages);
        }
      },
    ),
    setDialogMessageSent: updateDialogMessagesState<DialogMessageBaseAction>(
      (messagesState, { message }) => {
        insertDialogMessages(messagesState, [message]);
      },
    ),
    setUnansweredUnpaidMessageLoaded: updateDialogMessagesState<DialogMessageBaseAction>(
      (messagesState, { message }) => {
        messagesState.unansweredUnpaid.messageLoaded = true;
        insertDialogMessages(messagesState, [message]);
      },
    ),
    setMessageSendingFailed: updateDialogMessagesState<DialogMessageTagBaseAction>(
      (messagesState, { messageTag }) => {
        messagesState.messages = messagesState.messages.filter(m => m.tag !== messageTag);
      },
    ),
    setUnansweredUnpaidMessageAnswered: updateDialogMessagesState<ActionWithDialogId>(
      messagesState => {
        messagesState.unansweredUnpaid.messageAnswered = true;
      },
    ),
    setUploadMessageMediaStart: updateDialogMessagesState<DialogMessageBaseAction>(
      (messagesState, { message }) => {
        insertDialogMessages(messagesState, [message]);
        messagesState.messagesMediaUpload[message.tag] = {
          progress: 0,
        };
      },
    ),
    setUploadMessageMediaProgress: updateDialogMessagesState<SetMessageMediaUploadProgressAction>(
      (messagesState, { progress, messageTag }) => {
        const mediaUploadState = messagesState.messagesMediaUpload[messageTag];
        if (!mediaUploadState) {
          return;
        }
        mediaUploadState.progress = progress;
      },
    ),
    setUploadMessageMediaFinish: updateDialogMessagesState<DialogMessageTagBaseAction>(
      (messagesState, { messageTag }) => {
        delete messagesState.messagesMediaUpload[messageTag];
      },
    ),
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getDialogMessages: (state, dialogId: string) => state[dialogId]?.messages,
    getDialogSomeMessagesLoaded: (state, dialogId: string) =>
      state[dialogId]?.someMessagesLoaded ?? false,
    getDialogAllMessagesLoaded: (state, dialogId: string) =>
      state[dialogId]?.allMessagesLoaded ?? false,
    getDialogUnansweredUnpaidState: (state, dialogId: string) => state[dialogId]?.unansweredUnpaid,
    getMediaMessageUploadState: (state, dialogId: string, messageTag: string) =>
      state[dialogId]?.messagesMediaUpload[messageTag],
    getDialogMessagesAllowed: (state, dialogId: string) => state[dialogId]?.messagesAllowed,
  },
});
