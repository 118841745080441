import { createSlice } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';
import { setDialogMessageSent } from 'modules/domain/messages/model';

import {
  AnimatorPreferences,
  DialogExplicityPreferences,
  DialogPreferencesModelState,
} from './types';
import { updateDialogPreferencesState } from './helpers';

type SetDialogExplicityPreferencesAction = ActionWithDialogId<{
  explicityPreferences: DialogExplicityPreferences;
}>;
type SetAnimatorStoryToldAction = ActionWithDialogId<{
  animatorStoryTold: boolean;
}>;
type SetAnimatorPreferencesAction = ActionWithDialogId<{
  animatorPreferences: AnimatorPreferences;
}>;
type SetDialogExplicitStatusAction = ActionWithDialogId<{ status: boolean }>;

const initialState: DialogPreferencesModelState = {};

export const dialogPreferencesModel = createSlice({
  name: 'dialogPreferences',
  initialState,
  reducers: {
    setDialogExplicityPreferences: (state, action: SetDialogExplicityPreferencesAction) => {
      const { dialogId, explicityPreferences } = action.payload;
      if (state[dialogId]) {
        return;
      }
      state[dialogId] = explicityPreferences;
    },
    setAnimatorStoryTold: updateDialogPreferencesState<SetAnimatorStoryToldAction>(
      (preferencesState, { animatorStoryTold }) => {
        preferencesState.animatorStoryTold = animatorStoryTold;
      },
    ),
    setAnimatorPreferences: updateDialogPreferencesState<SetAnimatorPreferencesAction>(
      (preferencesState, { animatorPreferences }) => {
        preferencesState.animatorPreferences = animatorPreferences;
      },
    ),
    setDialogExplicitStatus: updateDialogPreferencesState<SetDialogExplicitStatusAction>(
      (preferencesState, { status }) => {
        preferencesState.isExplicit = status;
      },
    ),
  },
  extraReducers: builder => {
    builder.addCase(
      setDialogMessageSent,
      updateDialogPreferencesState(preferencesState => {
        preferencesState.canToggleExplicit = true;
      }),
    );
    addResetDialogStateReducer(() => initialState)(builder);
  },
  selectors: {
    getAnimatorPreferences: (state, dialogId: string) => state[dialogId]?.animatorPreferences,
    getDialogAnimatorStoryTold: (state, dialogId: string) => state[dialogId]?.animatorStoryTold,
    getIsExplicitPossibleForClient: (state, dialogId: string) =>
      state[dialogId]?.isExplicitPossibleForClient,
    getCanToggleExplicit: (state, dialogId: string) => state[dialogId]?.canToggleExplicit,
    getDialogIsExplicit: (state, dialogId: string) => state[dialogId]?.isExplicit,
  },
});
