import { SearchedDialogMessageDto, SearchedDialogMessagesDto } from 'modules/api/dto';

import { SearchedDialogMessage, SearchedDialogMessagesData } from './types';

const mapSearchedDialogMessageDto = (dto: SearchedDialogMessageDto): SearchedDialogMessage => ({
  messageId: dto.messageId,
  messageIndex: dto.n,
});

export const mapSearchedDialogMessagesDto = (
  dto: SearchedDialogMessagesDto,
): SearchedDialogMessagesData => ({
  totalCount: dto.totalCount,
  messages: dto.messages.map(mapSearchedDialogMessageDto),
});
