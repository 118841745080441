import { dialogModel } from './model';

const { reducer, reducerPath, actions, selectors } = dialogModel;

export const {
  setCurrentDialog,
  setDialogsQueueIsEmpty,
  setFoundDialog,
  resetFoundDialog,
  resetDialogState,
} = actions;
export const {
  getCurrentDialog,
  getDialogsQueueIsEmpty,
  getFoundDialog,
  getUnansweredDialogsCount,
} = selectors;
export { reducer, reducerPath as namespace };
export type { Dialog, DialogModelState, InitDialogStateData } from './types';
export { mapDialogsResponseDtoToInitData } from './adapter';
export { addResetDialogStateReducer } from './utils';
