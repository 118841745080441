import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { parseDialogId, getMessageTypeFromFile, useLogger } from 'modules/utils';
import { DialogMessageDto, DialogMessageMetaDto } from 'modules/api/dto';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { resetDialogMediaState } from 'modules/domain/media/model';
import { useCopilotAnalytics } from 'modules/domain/chat-input/hooks';

import { createBaseMessageDraft, getPhotoReference } from '../helpers';
import {
  MessageType,
  setUploadMessageMediaProgress,
  setUploadMessageMediaStart,
  setUploadMessageMediaFinish,
  mapDialogMessageDto,
} from '../model';

import { useSendDialogMessageBaseApi } from './use-send-dialog-message-base-api';

type SendPhotoMessageBaseData = {
  basename: string;
  meta: DialogMessageMetaDto;
  draft?: DialogMessageDto;
};

export const useSendDialogPhotoMessageApi = (dialogId: string) => {
  const dispatch = useDispatch();
  const { dialogsMedia: dialogsMediaApi } = useApi();
  const sendMessageBase = useSendDialogMessageBaseApi(dialogId);
  const { logError } = useLogger('useSendDialogPhotoMessageApi');
  const { animatorId, attendeeId } = parseDialogId(dialogId);
  const { trackCopilotUsageAndInjectMeta } = useCopilotAnalytics(animatorId, attendeeId);

  const trackCopilotUsageAndGenerateMeta = useCallback(
    (copilotUsed?: boolean) => {
      const meta: DialogMessageMetaDto = {};

      trackCopilotUsageAndInjectMeta({ copilotUsed, meta, type: MessageType.PHOTO });

      return meta;
    },
    [trackCopilotUsageAndInjectMeta],
  );

  const sendPhotoMessageBase = useCallback(
    async ({ basename, meta, draft }: SendPhotoMessageBaseData) => {
      const reference = getPhotoReference(basename);

      await sendMessageBase({ reference, meta }, draft);

      // TODO: once again relying on a timer:(
      // it takes some time at the backend side to update media state after message sent
      // replace it with the WS event listener once we have Websocket implemented
      setTimeout(() => {
        dispatch(resetDialogMediaState());
      }, 500);
    },
    [sendMessageBase, dispatch],
  );

  const sendPhotoMessage = useCallback(
    (basename: string, copilotUsed?: boolean) => {
      const meta = trackCopilotUsageAndGenerateMeta(copilotUsed);

      return sendPhotoMessageBase({ basename, meta });
    },
    [sendPhotoMessageBase, trackCopilotUsageAndGenerateMeta],
  );

  const sendPhotoMessageWithFileUpload = useCallback(
    async (file: File, copilotUsed?: boolean) => {
      const mediaUrl = URL.createObjectURL(file);
      const mediaType = getMessageTypeFromFile(file);

      if (mediaType !== MessageType.PHOTO) return;

      const meta = trackCopilotUsageAndGenerateMeta(copilotUsed);

      const draftMessage: DialogMessageDto = {
        ...createBaseMessageDraft(animatorId, attendeeId),
        meta: {
          ...meta,
          localPhotoUri: mediaUrl,
        },
      };
      const messageTag = draftMessage.tag;

      const message = mapDialogMessageDto(draftMessage, animatorId);
      dispatch(setUploadMessageMediaStart({ dialogId, message }));

      try {
        const basename = await dialogsMediaApi.uploadDialogMedia(
          animatorId,
          attendeeId,
          file,
          progress => {
            dispatch(setUploadMessageMediaProgress({ dialogId, messageTag, progress }));
          },
        );

        dispatch(setUploadMessageMediaFinish({ dialogId, messageTag }));

        await sendPhotoMessageBase({ basename, draft: draftMessage, meta });
      } catch (error) {
        logError('Failed to send a photo message with file upload', {
          error,
          message: draftMessage,
          dialogId,
        });

        dispatch(setUploadMessageMediaFinish({ dialogId, messageTag }));

        showChatErrorToast(
          'Failed to send a photo. Please check your connection and try again later',
        );
      }
    },
    [
      animatorId,
      attendeeId,
      dispatch,
      dialogsMediaApi,
      sendPhotoMessageBase,
      dialogId,
      logError,
      trackCopilotUsageAndGenerateMeta,
    ],
  );

  return {
    sendPhotoMessage,
    sendPhotoMessageWithFileUpload,
  };
};
