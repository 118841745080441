import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { toast, useLogger } from 'modules/utils';

import {
  mapDialogsResponseDtoToInitData,
  resetDialogState,
  setDialogsQueueIsEmpty,
} from '../model';

import { useSetDialogState } from './actions';

export const useDialogLoadApi = () => {
  const dispatch = useDispatch();
  const { dialogs: dialogsApi } = useApi();
  const { logError } = useLogger('useDialogLoadApi');
  const setDialogState = useSetDialogState();

  const loadNextDialog = useCallback(
    async (operatorId: string, currentServerDialogId?: number) => {
      try {
        dispatch(resetDialogState());

        const dialogsDto = await dialogsApi.getOperatorDialogs(operatorId, {
          exceptDialogId: currentServerDialogId,
        });

        const dialogInitData = mapDialogsResponseDtoToInitData(dialogsDto);

        if (!dialogInitData) {
          dispatch(setDialogsQueueIsEmpty());
          return;
        }

        setDialogState(dialogInitData);
      } catch (error) {
        logError('loadNextDialog error', { error });

        toast.showError('Failed to load next dialog. Check your connection and try again later');
      }
    },
    [dispatch, dialogsApi, logError, setDialogState],
  );

  return {
    loadNextDialog,
  };
};
