import { ChatInputModelState } from './types';

type SetInputTextOptions = {
  /**
   * Default: false
   */
  append?: boolean;
  /**
   * Default: true
   */
  setCopilotOptionEdited?: boolean;
};

export const setInputTextState = (
  state: ChatInputModelState,
  inputText: string,
  options?: SetInputTextOptions,
) => {
  const { append = false, setCopilotOptionEdited = true } = options || {};
  if (append) {
    state.inputText += inputText;
  } else {
    state.inputText = inputText;
  }

  if (state.copilot.lastUsedData && setCopilotOptionEdited) {
    state.copilot.lastUsedData.edited = true;
  }
};
