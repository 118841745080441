import React, {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useDialogLatestMessage } from 'modules/domain/messages/hooks';
import { MediaSelectorButton } from 'modules/components/chat/media-selector/button';
import { SmilePicker } from 'modules/components/common';
import { GiftsPicker } from 'modules/components/gift-picker';
import { useDialogMediaEnabled } from 'modules/domain/media/hooks';
import { useAudioMessagesAvailable, useIcebreakersEnabled } from 'modules/domain/features';
import { getDialogId, testId } from 'modules/utils';
import { AudioSelectorButton } from 'modules/components/chat/audio/selector/button';
import { IcebreakerPicker } from 'modules/components/icebreaker-picker';
import { useChatInputActions, useChatInputText } from 'modules/domain/chat-input/hooks';

import { TypedMessageIndicator } from './typed-message-indicator';
import { uncapsTheLastWord } from './utils';
import {
  useSendTextMessage,
  useTypedMessageIndicator,
  useChatInputTypingTrackerHandler,
  useChatInputFocuser,
  useValidateTextMessage,
} from './hooks';
import styles from './index.module.scss';

type Props = {
  animatorId: string;
  attendeeId: string;
};

const SEND_BTN_KEY = 'Enter';

export const ChatForm = memo((props: Props) => {
  const { animatorId, attendeeId } = props;

  const [smilesPickerIsVisible, setSmilesPickerVisible] = useState(false);

  const dialogId = getDialogId(animatorId, attendeeId);

  const inputText = useChatInputText();
  const { setInputFocused, setInputText, resetInputText } = useChatInputActions();
  const latestMessage = useDialogLatestMessage(dialogId);
  const indicator = useTypedMessageIndicator(inputText, latestMessage);
  const mediaInChatEnabled = useDialogMediaEnabled(dialogId);
  const icebreakersEnabled = useIcebreakersEnabled(dialogId);
  const audioMessagesEnabled = useAudioMessagesAvailable(dialogId);
  const handleTypingChange = useChatInputTypingTrackerHandler(dialogId, SEND_BTN_KEY);
  const validateTextMessage = useValidateTextMessage(latestMessage, indicator.status);
  const sendTextMessage = useSendTextMessage(animatorId, attendeeId);

  useEffect(() => {
    resetInputText();
  }, [dialogId, resetInputText]);

  const inputRef = useRef<HTMLInputElement>(null);

  useChatInputFocuser(dialogId, inputRef);

  const handleEnterPress = () => {
    const message = inputText.trim();
    if (!message) return;

    const isValid = validateTextMessage(message);
    if (!isValid) return;

    sendTextMessage(message);
    resetInputText();
  };

  const handleInputKeyPress = (
    event: KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>,
  ) => {
    handleTypingChange(event);

    switch (event.key) {
      case ' ':
        setInputText(uncapsTheLastWord(inputText));
        break;

      case 'Enter':
        handleEnterPress();
        break;
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleSmileSelect = useCallback(
    (emoji: string) => {
      setInputText(emoji, true);
    },
    [setInputText],
  );

  const toggleSmilesVisibility = useCallback(
    (visible: boolean) => setSmilesPickerVisible(!visible),
    [],
  );

  return (
    <div className={styles.container}>
      <SmilePicker
        isVisible={smilesPickerIsVisible}
        onSelect={handleSmileSelect}
        onToggleVisibility={toggleSmilesVisibility}
      />
      <input
        {...testId('send-message-input')}
        ref={inputRef}
        className={styles.input}
        type="text"
        placeholder="Type a message..."
        onKeyPress={handleInputKeyPress}
        onChange={handleInputChange}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        value={inputText}
      />
      <div className={styles.buttons}>
        {audioMessagesEnabled && <AudioSelectorButton dialogId={dialogId} />}
        <GiftsPicker parent="chat" dialogId={dialogId} />
        {mediaInChatEnabled && <MediaSelectorButton parent="chat" dialogId={dialogId} />}
        {icebreakersEnabled && <IcebreakerPicker parent="chat" dialogId={dialogId} />}
      </div>
      <TypedMessageIndicator status={indicator.status} percent={indicator.percent} />
    </div>
  );
});
