import { useRootSelector } from 'modules/hooks/redux';
import { getDialogSomeMessagesLoaded } from 'modules/domain/messages/model';

export const useDialogSomeMessagesLoaded = (dialogId: string) => {
  const dialogSomeMessagesLoaded = useRootSelector(state =>
    getDialogSomeMessagesLoaded(state, dialogId),
  );

  return dialogSomeMessagesLoaded;
};
