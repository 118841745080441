import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getDialogId, parseDialogId, useLogger } from 'modules/utils';
import { useApi } from 'modules/api';
import { UpdateDialogExplicitStatusPayload } from 'modules/api/payload';
import { DialogNotesOldDto } from 'modules/api/dto';
import { useDialogAnalytics } from 'modules/domain/dialog/hooks';
import { useOperatorId } from 'modules/domain/auth';

import {
  getAnimatorStoryToldFromDto,
  mapDialogAnimatorPreferencesDto,
  setAnimatorPreferences,
  setAnimatorStoryTold,
  setDialogExplicitStatus,
} from '../model';

export const useDialogPreferencesApi = () => {
  const operatorId = useOperatorId();
  const dispatch = useDispatch();
  const { logError } = useLogger('useDialogPreferencesApi');
  const { dialogsNotes: dialogsNotesApi, animators: animatorsApi, dialogs: dialogsApi } = useApi();
  const { trackAnimatorStoryTold, trackDialogIsExplicit } = useDialogAnalytics();

  const loadAnimatorPreferences = useCallback(
    async (animatorId: string, attendeeId: string) => {
      const dialogId = getDialogId(animatorId, attendeeId);

      try {
        const preferencesDto = await animatorsApi.getPreferences(animatorId);
        const preferences = mapDialogAnimatorPreferencesDto(preferencesDto);
        dispatch(setAnimatorPreferences({ dialogId, animatorPreferences: preferences }));
      } catch (error) {
        logError('fetchAnimatorPreferences error', { dialogId, animatorId, error });
      }
    },
    [dispatch, animatorsApi, logError],
  );

  const loadAnimatorStoryTold = useCallback(
    async (animatorId: string, attendeeId: string) => {
      const dialogId = getDialogId(animatorId, attendeeId);

      try {
        const notesOldDto = await dialogsNotesApi.getDialogNotesOld(animatorId, attendeeId);

        const animatorStoryTold = getAnimatorStoryToldFromDto(notesOldDto);

        dispatch(setAnimatorStoryTold({ dialogId, animatorStoryTold }));
      } catch (error) {
        logError('loadAnimatorStoryTold error', { dialogId, error });
      }
    },
    [dispatch, dialogsNotesApi, logError],
  );

  const updateAnimatorStoryTold = useCallback(
    async (dialogId: string, animatorStoryTold: boolean) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const payload: DialogNotesOldDto = { storyTold: String(animatorStoryTold) };
      try {
        await dialogsNotesApi.patchDialogNotesOld(animatorId, attendeeId, payload);

        if (animatorStoryTold) {
          trackAnimatorStoryTold(animatorId, attendeeId);
        }

        dispatch(setAnimatorStoryTold({ dialogId, animatorStoryTold }));
      } catch (error) {
        logError('updateAnimatorStoryTold error', { dialogId, payload, error });
      }
    },
    [dispatch, dialogsNotesApi, logError, trackAnimatorStoryTold],
  );

  const updateDialogExplicitStatus = useCallback(
    async (dialogId: string, status: boolean) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const payload: UpdateDialogExplicitStatusPayload = {
        operatorId,
        animatorId,
        attendeeId,
        status,
      };
      try {
        if (status) {
          trackDialogIsExplicit(animatorId, attendeeId);
        }
        await dialogsApi.updateDialogExplicitStatus(payload);
        dispatch(setDialogExplicitStatus({ dialogId, status }));
      } catch (error) {
        logError('Failed to set dialog explicit status', { error, payload });
      }
    },
    [dialogsApi, dispatch, operatorId, trackDialogIsExplicit, logError],
  );

  return {
    loadAnimatorPreferences,
    loadAnimatorStoryTold,
    updateAnimatorStoryTold,
    updateDialogExplicitStatus,
  };
};
