import React, { useRef } from 'react';
import classNames from 'classnames';

import { getDialogId, testId } from 'modules/utils';
import { CopilotOptionType } from 'modules/domain/chat-input/model';
import { GiftsPicker } from 'modules/components/gift-picker';
import { MediaSelectorButton } from 'modules/components/chat/media-selector/button';
import { IcebreakerPicker } from 'modules/components/icebreaker-picker';

import optionStyles from '../option/styles.module.scss';

import styles from './styles.module.scss';

type Prop = {
  type: CopilotOptionType;
  animatorId: string;
  attendeeId: string;
};

type SpecialOptionType = Exclude<
  CopilotOptionType,
  CopilotOptionType.Trap | CopilotOptionType.Valid
>;

const typeToTextMap: Record<SpecialOptionType, string> = {
  [CopilotOptionType.Gift]: 'Send a Gift',
  [CopilotOptionType.Photo]: 'Send a Photo',
  [CopilotOptionType.Icebreaker]: 'Send PurPur Question',
};

export const CopilotSpecialOption = (prop: Prop) => {
  const { type, animatorId, attendeeId } = prop;

  const dialogId = getDialogId(animatorId, attendeeId);

  const actionBtnRef = useRef<HTMLButtonElement>(null);

  const handleOptionClick = () => {
    actionBtnRef.current?.click();
  };

  const renderAction = () => {
    switch (type) {
      case CopilotOptionType.Gift:
        return (
          <GiftsPicker
            parent="copilot"
            dialogId={dialogId}
            btnRef={actionBtnRef}
            btnClassName={styles.pickerBtn}
          />
        );

      case CopilotOptionType.Photo:
        return (
          <MediaSelectorButton
            parent="copilot"
            btnRef={actionBtnRef}
            btnClassName={styles.pickerBtn}
            menuClassName={styles.mediaDropdownMenu}
            dialogId={dialogId}
          />
        );

      case CopilotOptionType.Icebreaker:
        return (
          <IcebreakerPicker
            parent="copilot"
            btnRef={actionBtnRef}
            modalClassName={styles.icebrakerModal}
            btnClassName={styles.pickerBtn}
            dialogId={dialogId}
          />
        );

      default:
        return null;
    }
  };

  const text = typeToTextMap[type as SpecialOptionType];

  return (
    <div
      onClick={handleOptionClick}
      className={classNames(optionStyles.option, styles.specialOption)}
      {...testId(`copilot-option-${type}`)}
    >
      <div className={styles.actionContainer} onClick={e => e.stopPropagation()}>
        {renderAction()}
      </div>
      <span {...testId('copilot-special-option-text')} className={optionStyles.optionText}>
        {text}
      </span>
    </div>
  );
};
