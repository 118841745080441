import { useEffect } from 'react';

import { useApi } from 'modules/api';

import { useDialogLatestMessage } from './selectors';

export function useMarkLastMessageAsRead(dialogId: string) {
  const lastMessage = useDialogLatestMessage(dialogId);

  const { dialogsMessages: dialogsMessagesApi } = useApi();

  useEffect(() => {
    if (lastMessage && !lastMessage.outgoing && !lastMessage.read) {
      const { senderId, recipientId, id } = lastMessage;
      // switching sender and recipient is intended here
      dialogsMessagesApi.markMessageAsRead(recipientId, senderId, id);
    }
  }, [lastMessage, dialogsMessagesApi]);
}
