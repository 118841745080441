import { chatInputModel } from './model';

const { reducer, reducerPath, actions, selectors } = chatInputModel;

export const {
  setCopilotData,
  resetCopilotData,
  setCopilotDataLoadingStart,
  setCopilotDataLoadingFailed,
  setCopilotTextOptionSelected,
  setInputFocused,
  setInputText,
  resetInputText,
  setFastAnswerSelected,
} = actions;
export const {
  getCopilotData,
  getCopilotLastUsedData,
  getInputFocused,
  getInputText,
  getSelectedFastAnswer,
} = selectors;
export { reducer, reducerPath as namespace };

export { mapAutoGeneratedAnswersDto } from './adapter';

export type {
  ChatInputModelState,
  CopilotState,
  CopilotData,
  CopilotLastUsedData,
  CopilotOption,
} from './types';
export { CopilotOptionType, CopilotUsage } from './types';
