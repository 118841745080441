import { configureStore } from '@reduxjs/toolkit';

import {
  reducer as fastAnswersReducer,
  namespace as fastAnswersNamespace,
} from 'modules/domain/fast-answers/model';
import { UserModel } from 'modules/domain/users';
import {
  reducer as dialogModelReducer,
  namespace as dialogModelNamespace,
} from 'modules/domain/dialog/model';
import {
  reducer as dialogMessagesModelReducer,
  namespace as dialogMessagesModelNamespace,
} from 'modules/domain/messages/model';
import { reducer as mediaReducer, namespace as mediaNamespace } from 'modules/domain/media/model';
import {
  reducer as dictionariesReducer,
  namespace as dictionariesNamespace,
} from 'modules/domain/dictionaries/model';
import {
  namespace as modalsNamespace,
  reducer as modalsReducer,
} from 'modules/domain/modals/model';
import { reducer as spotifyMatchReducer } from 'modules/domain/spotify-match/reducer';
import {
  reducer as earningsReducer,
  namespace as earningsNamespace,
} from 'modules/domain/earnings/model';
import { reducer as giftsReducer, namespace as giftsNamespace } from 'modules/domain/gifts/model';
import {
  namespace as operatorNamespace,
  reducer as operatorReducer,
} from 'modules/domain/operator/model';
import {
  reducer as calendarReducer,
  namespace as calendarNamespace,
} from 'modules/domain/calendar/model';
import {
  namespace as chatInputNamespace,
  reducer as chatInputReducer,
} from 'modules/domain/chat-input/model';
import {
  namespace as audioMessagesNamespace,
  reducer as audioMessagesReducer,
} from 'modules/domain/audio/model';
import {
  namespace as messagesSearchNamespace,
  reducer as messagesSearchReducer,
} from 'modules/domain/messages-search/model';
import {
  namespace as dialogNotesNamespace,
  reducer as dialogNotesReducer,
} from 'modules/domain/notes/model';
import {
  namespace as dialogPreferencesNamespace,
  reducer as dialogPreferencesReducer,
} from 'modules/domain/preferences/model';

export const configureReduxStore = () => {
  const store = configureStore({
    reducer: {
      ...UserModel.reducer,
      [dialogModelNamespace]: dialogModelReducer,
      [dialogMessagesModelNamespace]: dialogMessagesModelReducer,
      [mediaNamespace]: mediaReducer,
      [dictionariesNamespace]: dictionariesReducer,
      [modalsNamespace]: modalsReducer,
      [fastAnswersNamespace]: fastAnswersReducer,
      ...spotifyMatchReducer,
      [earningsNamespace]: earningsReducer,
      [giftsNamespace]: giftsReducer,
      [operatorNamespace]: operatorReducer,
      [calendarNamespace]: calendarReducer,
      [chatInputNamespace]: chatInputReducer,
      [audioMessagesNamespace]: audioMessagesReducer,
      [messagesSearchNamespace]: messagesSearchReducer,
      [dialogNotesNamespace]: dialogNotesReducer,
      [dialogPreferencesNamespace]: dialogPreferencesReducer,
    },
  });

  return store;
};
