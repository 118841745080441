import React, { memo, Ref } from 'react';

import mediaLibraryIcon from 'images/media-library-icon.svg';
import mediaDeviceIcon from 'images/media-device-icon.svg';
import mediaWebcamIcon from 'images/media-webcam-icon.svg';
import photoIcon from 'images/photo-icon.svg';
import { useModalActions } from 'modules/domain/modals/hooks';
import { useDialogMediaVisible } from 'modules/domain/media/hooks';
import { useSendDialogPhotoMessageApi } from 'modules/domain/messages/hooks';
import { ModalNames } from 'modules/domain/modals/model';
import { TooltipMenu } from 'modules/components/tooltip-menu';
import { MenuItemProps } from 'modules/components/tooltip-menu/menu-item';
import { IconButton } from 'modules/components/common/button';
import { testId } from 'modules/utils';
import { MediaPickerParent, MediaSelectorModalData } from 'modules/constants';

type Props = {
  btnRef?: Ref<HTMLButtonElement>;
  btnClassName?: string;
  menuClassName?: string;
  dialogId: string;
  parent: MediaPickerParent;
};

export const MediaSelectorButton = memo((props: Props) => {
  const { btnRef, btnClassName, menuClassName, dialogId, parent } = props;

  const { openModal } = useModalActions();
  const { sendPhotoMessageWithFileUpload } = useSendDialogPhotoMessageApi(dialogId);
  const isMediaFromLibraryVisible = useDialogMediaVisible(dialogId);

  const options: MenuItemProps[] = [];

  const openMediaModal = (name: ModalNames) => {
    const modalData: MediaSelectorModalData = { parent };
    openModal(name, modalData);
  };

  if (isMediaFromLibraryVisible) {
    options.push({
      icon: <img src={mediaLibraryIcon} />,
      onClick: () => openMediaModal(ModalNames.MediaSelector),
      caption: 'Choose from Library',
      testIdPrefix: 'choose-from-library-option',
    });
  }

  options.push(
    {
      icon: <img src={mediaDeviceIcon} />,
      onChange: (files: File[]) => {
        sendPhotoMessageWithFileUpload(files[0], parent === 'copilot');
      },
      fileInput: true,
      caption: 'Choose from Device',
      testIdPrefix: 'choose-from-device-option',
    },
    {
      icon: <img src={mediaWebcamIcon} />,
      onClick: () => openMediaModal(ModalNames.WebcamMedia),
      caption: 'Take via Webcam',
      testIdPrefix: 'take-via-webcam-option',
    },
  );

  return (
    <TooltipMenu
      options={options}
      menuClassName={menuClassName}
      trigger={
        <IconButton ref={btnRef} className={btnClassName} {...testId('media-selector__button')}>
          <img src={photoIcon} />
        </IconButton>
      }
      position="top-right"
    />
  );
});
