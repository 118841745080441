import React, { memo, useCallback } from 'react';

import { testId } from 'modules/utils';
import { useDialogIsExplicit, useDialogPreferencesApi } from 'modules/domain/preferences/hooks';
import { useOperatorInfo } from 'modules/domain/operator/hooks';

import styles from './styles.module.scss';

type Props = {
  dialogId: string;
};

export const ExplicitBanner = memo((props: Props) => {
  const { dialogId } = props;
  const { updateDialogExplicitStatus } = useDialogPreferencesApi();

  const dialogIsExplicit = useDialogIsExplicit(dialogId);
  const { isReadyForExplicit: operatorReadyForExplicit } = useOperatorInfo() || {};

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateDialogExplicitStatus(dialogId, event.target.checked);
    },
    [dialogId, updateDialogExplicitStatus],
  );

  if (operatorReadyForExplicit && !dialogIsExplicit) {
    return null;
  }

  return (
    <div {...testId('explicit-banner-container')} className={styles.container}>
      <label className={styles.label}>
        <input
          className={styles.input}
          type="checkbox"
          checked={dialogIsExplicit}
          onChange={onChange}
        />
        <span>Explicit chat</span>
      </label>
    </div>
  );
});
