import { createSlice } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import { MessagesSearchModelState, SearchedDialogMessagesData } from './types';

export type SetMessagesSearchResultAction = ActionWithDialogId<{
  searchedPhrase: string;
  result: SearchedDialogMessagesData;
}>;
export type SwitchSearchedMessageIndexAction = ActionWithDialogId<{
  searchedMessageIndex: number;
}>;

const initialState: MessagesSearchModelState = {};

export const messagesSearchModel = createSlice({
  name: 'messagesSearch',
  initialState,
  reducers: {
    startMessagesSearch: (state, { payload: { dialogId } }: ActionWithDialogId) => {
      state[dialogId] = {
        searchedPhrase: '',
        currentSearchedMessageIndex: 0,
      };
    },
    resetMessagesSearch: () => initialState,
    setMessagesSearchResult: (
      state,
      { payload: { dialogId, searchedPhrase, result } }: SetMessagesSearchResultAction,
    ) => {
      state[dialogId] = {
        result,
        searchedPhrase,
        currentSearchedMessageIndex: 0,
      };
    },
    resetMessagesSearchResult: (state, { payload: { dialogId } }: ActionWithDialogId) => {
      state[dialogId] = {
        searchedPhrase: '',
        currentSearchedMessageIndex: 0,
      };
    },
    switchSearchedMessageIndex: (
      state,
      { payload: { dialogId, searchedMessageIndex } }: SwitchSearchedMessageIndexAction,
    ) => {
      const messagesSearchState = state[dialogId];
      if (messagesSearchState) {
        messagesSearchState.currentSearchedMessageIndex = searchedMessageIndex;
      }
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getMessagesSearchState: (state, dialogId: string) => state[dialogId],
    getMessagesSearchActive: (state, dialogId: string) => !!state[dialogId],
  },
});
