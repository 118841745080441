import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  resetMessagesSearch as resetMessagesSearchAction,
  startMessagesSearch as startMessagesSearchAction,
} from '../model';

import { useDialogMessagesSearchActive } from './selectors';

export const useDialogMessagesSearchActivityHandlers = (dialogId: string) => {
  const messagesSearchActive = useDialogMessagesSearchActive(dialogId);
  const dispatch = useDispatch();

  const startMessagesSearch = useCallback(() => {
    dispatch(startMessagesSearchAction({ dialogId }));
  }, [dispatch, dialogId]);

  const resetMessagesSearch = useCallback(() => {
    dispatch(resetMessagesSearchAction());
  }, [dispatch]);

  return {
    messagesSearchActive,
    startMessagesSearch,
    resetMessagesSearch,
  };
};
