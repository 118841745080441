import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  AudioMessageTemplate,
  setAudioMessages as setAudioMessagesAction,
  resetAudioMessages as resetAudioMessagesAction,
} from 'modules/domain/audio/model';

export const useAudioMessagesActions = () => {
  const dispatch = useDispatch();

  const setAudioMessages = useCallback(
    (dialogId: string, audioMessages: AudioMessageTemplate[]) =>
      dispatch(setAudioMessagesAction({ dialogId, audioMessages })),
    [dispatch],
  );

  const resetAudioMessages = useCallback(() => dispatch(resetAudioMessagesAction()), [dispatch]);

  return {
    setAudioMessages,
    resetAudioMessages,
  };
};
