import { useCallback } from 'react';

import { useSendDialogMessageApi } from 'modules/domain/messages/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { useModalActions } from 'modules/domain/modals/hooks';
import { createMessageReference } from 'modules/utils';
import { MessageType } from 'modules/domain/messages/model';
import { NetworkErrorStatus, HttpError } from 'modules/api';

import { AudioMessageTemplate } from '../model';

import { useAudioMessagesTemplates } from './use-audio-messages-templates';

export const useSendAudioMessageTemplate = (dialogId: string) => {
  const { loadAudioMessagesTemplates } = useAudioMessagesTemplates(dialogId);
  const { closeModal } = useModalActions();

  const sendDialogMessage = useSendDialogMessageApi(dialogId);

  const sendAudioMessageTemplate = useCallback(
    async (template: AudioMessageTemplate) => {
      closeModal();

      try {
        await sendDialogMessage({
          reference: createMessageReference(MessageType.AUDIO, template.basename),
          meta: {
            audioMessageId: template.id,
            transcription: { text: template.text },
          },
          type: MessageType.AUDIO,
        });
      } catch (error) {
        const errorMessage =
          (error as HttpError).status === NetworkErrorStatus.Conflict
            ? "You can't send audio messages to this user at the moment. Please try again later."
            : 'Failed to send an audio message. Please check your connection and try again later';

        showChatErrorToast(errorMessage);
      }
      loadAudioMessagesTemplates();
    },
    [sendDialogMessage, closeModal, loadAudioMessagesTemplates],
  );

  return sendAudioMessageTemplate;
};
