import { modalsModel } from './model';

const { reducer, reducerPath, actions, selectors } = modalsModel;

export const { setActiveModal, closeActiveModal } = actions;
export const { getActiveModalData, getActiveModalName } = selectors;
export { reducer, reducerPath as namespace };

export type { ModalsModelState } from './types';
export { ModalNames } from './types';
