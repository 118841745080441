import { useDialogLatestMessage, useMediaMessageUploadState } from 'modules/domain/messages/hooks';

export const useCanSwitchDialog = (dialogId: string) => {
  const latestMessage = useDialogLatestMessage(dialogId);
  const mediaMessageUploadState = useMediaMessageUploadState(dialogId, latestMessage?.tag || '');

  const mediaUploadInProgress = !!mediaMessageUploadState;

  return !!latestMessage?.outgoing && !mediaUploadInProgress;
};
