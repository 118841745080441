import { getDialogId } from 'modules/utils';
import { DialogDto, DialogsResponseDto } from 'modules/api/dto';
import { mapDialogDtoToExplicityPreferences } from 'modules/domain/preferences/model';
import { mapDialogDtoToMessagesInitState } from 'modules/domain/messages/model';

import { Dialog, InitDialogStateData } from './types';

const mapDialogDtoToDialog = (dto: DialogDto): Dialog => {
  const animatorId = dto.Animator.SiteId;
  const attendeeId = dto.Client.SiteId;
  const id = getDialogId(animatorId, attendeeId);

  return {
    id,
    serverId: dto.Id,
    animatorId,
    attendeeId,
    chatStartTimestamp: dto.ChatStartTimestamp,
  };
};

export const mapDialogsResponseDtoToInitData = (
  response: DialogsResponseDto,
): InitDialogStateData | null => {
  const {
    Dialogs: [dialogDto],
    Unanswered,
  } = response;

  if (!dialogDto) {
    return null;
  }

  return {
    dialog: mapDialogDtoToDialog(dialogDto),
    messagesInitState: mapDialogDtoToMessagesInitState(dialogDto),
    explicityPreferences: mapDialogDtoToExplicityPreferences(dialogDto),
    unansweredDialogsCount: Unanswered.Count,
  };
};
