import { useEffect } from 'react';

import { useAudioMessagesTemplates } from 'modules/domain/audio/hooks';

export const useAudioMessagesAvailable = (dialogId: string) => {
  const { audioMessagesTemplates, loadAudioMessagesTemplates } =
    useAudioMessagesTemplates(dialogId);

  useEffect(() => {
    loadAudioMessagesTemplates(true);
  }, [loadAudioMessagesTemplates]);

  return !!audioMessagesTemplates?.length;
};
