import { MessageType } from 'modules/domain/messages/model';

const messageTypesMap: Record<MessageType, string> = {
  [MessageType.AUDIO]: 'audio',
  [MessageType.GIFT]: 'gift',
  [MessageType.ICEBREAKER]: 'purpur',
  [MessageType.PHOTO]: 'photo',
  [MessageType.TEXT]: 'text',
};

export const COPILOT_SPEC_OPTION_PREFIX = 'copilot:';

export function transformMessageTypeForCopilotAnalytics(
  messageType: MessageType,
  copilotUsed?: boolean,
): string {
  const transformedType = messageTypesMap[messageType];

  if (copilotUsed) {
    return `${COPILOT_SPEC_OPTION_PREFIX}${transformedType}`;
  }

  return transformedType;
}
