import { useCallback } from 'react';

import { PollerFn, usePoller } from 'modules/hooks';
import { useConfig } from 'modules/config';

import { useDialogMediaEnabled } from './selectors';
import { useDialogMediaApi } from './use-dialog-media-api';
import { useDialogMediaAllowed } from './use-dialog-media-allowed';

export const useDialogMediaStatePoller = (dialogId: string) => {
  const { loadDialogMediaState } = useDialogMediaApi();
  const { chatMediaStatePollingInterval } = useConfig();

  const allowed = useDialogMediaAllowed(dialogId);
  const enabled = useDialogMediaEnabled(dialogId);

  // No need to poll media state if the media is enabled/available in the dialog
  const shouldPoll = allowed && !enabled;

  const checkChatMediaState = useCallback<PollerFn>(
    async stopPoller => {
      if (!shouldPoll) {
        stopPoller();

        return;
      }

      // keep checking state until the chat media enabled
      loadDialogMediaState(dialogId);
    },
    [dialogId, shouldPoll, loadDialogMediaState],
  );

  usePoller(checkChatMediaState, chatMediaStatePollingInterval);
};
