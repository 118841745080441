import React, { memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import { useDialogMediaEnabled } from 'modules/domain/media/hooks';
import { useSendDialogPhotoMessageApi } from 'modules/domain/messages/hooks';
import iconCustomPhoto from 'images/icon-custom-photo.svg';

import styles from './index.module.scss';

type Props = {
  dialogId: string;
  width: number;
  height: number;
};

export const ChatDropzone = memo((props: Props) => {
  const { dialogId, width, height } = props;

  const { sendPhotoMessageWithFileUpload } = useSendDialogPhotoMessageApi(dialogId);
  const mediaInChatEnabled = useDialogMediaEnabled(dialogId);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        sendPhotoMessageWithFileUpload(acceptedFiles[0]);
      }
    },
    [sendPhotoMessageWithFileUpload],
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  if (!mediaInChatEnabled) {
    return null;
  }

  return (
    <div
      {...getRootProps({
        className: classNames(
          styles.dropzoneContainer,
          isDragAccept && styles.dropzoneActiveContainer,
        ),
      })}
    >
      <input {...getInputProps()} />

      {isDragAccept && (
        <div className={styles.dropzoneContentWrapper} style={{ height, width }}>
          <div className={styles.dropzoneContent}>
            <img src={iconCustomPhoto} alt={''} />
            <p>Drop photos here to send them</p>
          </div>
        </div>
      )}
    </div>
  );
});
