import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { usePoller } from 'modules/hooks';
import { useLoadDialogMessagesApi } from 'modules/domain/messages/hooks';

export const useMessagesPoller = (currentDialogId: string) => {
  const { loadDialogMessages } = useLoadDialogMessagesApi(currentDialogId);

  const { chatMessagesPollingInterval, chatPageSize } = useConfig();

  const messagesPoller = useCallback(() => {
    loadDialogMessages(chatPageSize, 0);
  }, [loadDialogMessages, chatPageSize]);

  usePoller(messagesPoller, chatMessagesPollingInterval);
};
