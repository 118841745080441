import { ActionWithDialogId } from 'modules/domain/common/types';

import { DialogPreferencesModelState, DialogPreferences } from './types';

export const updateDialogPreferencesState =
  <Action extends ActionWithDialogId = ActionWithDialogId>(
    updateFn: (preferencesState: DialogPreferences, payload: Action['payload']) => void,
  ) =>
  (state: DialogPreferencesModelState, { payload }: Action) => {
    const dialogPreferencesState = state[payload.dialogId];
    if (!dialogPreferencesState) {
      return;
    }

    updateFn(dialogPreferencesState, payload);
  };
