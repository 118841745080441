import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addLogoutReducer } from 'modules/domain/auth/actions';

import { ModalsModelState, ModalNames } from './types';

type SetActiveModalAction = PayloadAction<{
  modalName: ModalNames;
  modalData?: any;
}>;

const initialState: ModalsModelState = {
  activeModalName: null,
  activeModalData: null,
};

export const modalsModel = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setActiveModal: (state, action: SetActiveModalAction) => {
      const { modalName, modalData } = action.payload;
      state.activeModalName = modalName;
      state.activeModalData = modalData;
    },
    closeActiveModal: () => initialState,
  },
  extraReducers: addLogoutReducer(() => initialState),
  selectors: {
    getActiveModalName: state => state.activeModalName,
    getActiveModalData: state => state.activeModalData,
  },
});
