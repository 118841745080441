import { IHttpTransport } from 'modules/api/transport/http.contracts';

import {
  GetDialogDaysWithMessagesQueryParams,
  GetDialogsQueryParams,
  IDialogsDomain,
  UpdateDialogExplicitStatusPayload,
} from './contracts';
import { DaysWithMessagesResponseDto, DayWithMessagesDto, DialogsResponseDto } from './dto';

export class DialogsDomain implements IDialogsDomain {
  constructor(private readonly transport: IHttpTransport, private readonly realm: string) {}

  getOperatorDialogs(
    operatorId: string,
    params?: GetDialogsQueryParams,
  ): Promise<DialogsResponseDto> {
    return this.transport
      .get<DialogsResponseDto>(`/multichat/operators/${operatorId}/dialogs`, {
        params: { realm: this.realm, ...params },
      })
      .then(response => response.data);
  }

  updateDialogExplicitStatus(payload: UpdateDialogExplicitStatusPayload): Promise<void> {
    const { operatorId, animatorId, attendeeId, status } = payload;
    return this.transport
      .put<void>('/multichat/dialogs/explicit/update', {
        operatorId,
        animatorId,
        clientId: attendeeId,
        isExplicit: status,
      })
      .then(response => response.data);
  }

  getDialogDaysWithMessages(
    animatorId: string,
    attendeeId: string,
    params: GetDialogDaysWithMessagesQueryParams,
  ): Promise<DayWithMessagesDto[]> {
    return this.transport
      .get<DaysWithMessagesResponseDto>(`/dialogs/messages/${animatorId}/${attendeeId}/dates`, {
        params,
      })
      .then(response => response.data.dates);
  }
}
