import { useEffect, useMemo } from 'react';

import { useUserHasExperimentTag, UserExperimentTag } from 'modules/domain/users/hooks';
import { DialogMessage } from 'modules/domain/messages/model';

import { useCopilotDataState } from './selectors';
import { useCopilotApi } from './use-copilot-api';

export function useCopilotData(animatorId: string, attendeeId: string, lastMessage: DialogMessage) {
  const { loadCopilotData } = useCopilotApi();

  const copilotDataState = useCopilotDataState(animatorId, attendeeId, lastMessage.id);

  useEffect(() => {
    if (lastMessage.outgoing || copilotDataState) {
      return;
    }

    loadCopilotData(animatorId, attendeeId, lastMessage.id);
  }, [lastMessage, animatorId, attendeeId, loadCopilotData, copilotDataState]);

  const specialOptionEnabled = useUserHasExperimentTag(
    attendeeId,
    UserExperimentTag.CopilotSpecialOptions,
  );

  const copilotData = useMemo(
    () => ({
      ...copilotDataState,
      options: copilotDataState?.options || [],
      specialOption: specialOptionEnabled ? copilotDataState?.specialOption : undefined,
    }),
    [copilotDataState, specialOptionEnabled],
  );

  return copilotData;
}
