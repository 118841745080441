import update, { Spec } from 'immutability-helper';
import { ReducerMapValue } from 'redux-actions';

/**
 * @deprecated this will be deleted after the storage refactoring (from common redux to redux-toolkit)
 */
export const setStateSpec =
  <State, Payload>(fn: (payload: Payload) => Spec<State>): ReducerMapValue<State, Payload> =>
  (state, { payload }) =>
    update(state, fn(payload));
