import { createSlice } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import { DialogNotes, DialogNotesModelState } from './types';

type SetDialogNotesAction = ActionWithDialogId<{ notes: DialogNotes }>;

const initialState: DialogNotesModelState = {};

export const dialogNotesModel = createSlice({
  name: 'dialogNotes',
  initialState,
  reducers: {
    setDialogNotes: (state, action: SetDialogNotesAction) => {
      const { notes, dialogId } = action.payload;
      state[dialogId] = notes;
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getDialogNotes: (state, dialogId: string) => state[dialogId],
  },
});
