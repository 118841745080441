import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';
import { NetworkErrorStatus, HttpError, useApi } from 'modules/api';
import { parseDialogId, useLogger } from 'modules/utils';
import { DialogMessageDto } from 'modules/api/dto';
import { SendMessagePayload } from 'modules/api/payload';
import { useDialogLoadApi } from 'modules/domain/dialog/hooks';

import { createBaseMessageDraft } from '../helpers';
import {
  setMessageSendingFailed,
  SendDialogMessageBaseData,
  setDialogMessageSent,
  mapDialogMessageDto,
} from '../model';

export const useSendDialogMessageBaseApi = (dialogId: string) => {
  const dispatch = useDispatch();
  const operatorId = useOperatorId();
  const { dialogsMessages: dialogsMessagesApi } = useApi();
  const { logError } = useLogger('useSendDialogMessageBaseApi');
  const { loadNextDialog } = useDialogLoadApi();

  const sendMessageBase = useCallback(
    async (data: SendDialogMessageBaseData, draft?: DialogMessageDto) => {
      const { meta, text, reference } = data;

      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const messageDraft: DialogMessageDto = {
        ...createBaseMessageDraft(animatorId, attendeeId, text),
        ...draft,
        meta: {
          ...draft?.meta,
          ...meta,
        },
      };

      if (reference) {
        messageDraft.meta.reference = reference;
      }

      const message = mapDialogMessageDto(messageDraft, animatorId);
      dispatch(setDialogMessageSent({ dialogId, message }));

      try {
        const messageBody: SendMessagePayload = {
          tag: messageDraft.tag,
          text: messageDraft.text,
          reference,
          instant: 1,
          operator: operatorId,
          meta,
        };

        await dialogsMessagesApi.sendMessage(animatorId, attendeeId, messageBody);
      } catch (error) {
        // Conflict means the dialog is taken by the other person or bot.
        // No need to log this error just to switch the dialog
        if ((error as HttpError).status === NetworkErrorStatus.Conflict) {
          loadNextDialog(operatorId);

          return;
        }
        logError('Failed to send a message', { error, message: messageDraft, dialogId });

        dispatch(setMessageSendingFailed({ dialogId, messageTag: messageDraft.tag }));

        throw error;
      }
    },
    [dialogsMessagesApi, dialogId, dispatch, operatorId, logError, loadNextDialog],
  );

  return sendMessageBase;
};
