import { useRootSelector } from 'modules/hooks/redux';
import { getMessagesSearchState } from 'modules/domain/messages-search/model';

export const useDialogMessagesSearchState = (dialogId: string) => {
  const dialogMessagesSearchState = useRootSelector(state =>
    getMessagesSearchState(state, dialogId),
  );

  return dialogMessagesSearchState;
};
