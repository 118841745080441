import { CopilotLastUsedData, CopilotUsage } from 'modules/domain/chat-input/model';

import { COPILOT_SPEC_OPTION_PREFIX } from './transform-message-type-for-copilot-analytics';

export const getCopilotUsage = (
  messageTypeForCopilot: string,
  copilotLastUsedData: CopilotLastUsedData | null,
): CopilotUsage => {
  // the order is important here
  if (messageTypeForCopilot.startsWith(COPILOT_SPEC_OPTION_PREFIX)) {
    return CopilotUsage.Used;
  } else if (copilotLastUsedData?.edited) {
    return CopilotUsage.Edited;
  } else if (copilotLastUsedData) {
    return CopilotUsage.Used;
  }

  return CopilotUsage.NotUsed;
};
