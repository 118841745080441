import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { getDialogId } from 'modules/utils';
import { DialogMessagesAllowed } from 'modules/domain/messages/model';

import { InitDialogStateData, resetFoundDialog as resetFoundDialogAction } from '../model';

import { useDialogAnalytics } from './use-dialog-analytics';
import { useSetDialogState } from './actions';

// This is not an actual search. There's no search api at the moment.
export const useDialogsSearch = () => {
  const dispatch = useDispatch();
  const { trackSearchDialog } = useDialogAnalytics();
  const setDialogState = useSetDialogState();

  const searchDialog = useCallback(
    async (attendeeId: string, animatorId: string) => {
      const id = getDialogId(animatorId, attendeeId);

      trackSearchDialog(animatorId, attendeeId);

      const initData: InitDialogStateData = {
        dialog: {
          serverId: 0,
          id,
          animatorId,
          attendeeId,
        },
        explicityPreferences: {
          isExplicit: false,
          isExplicitPossibleForClient: false,
        },
        messagesInitState: {
          messagesAllowed: DialogMessagesAllowed.All,
          unansweredUnpaid: {
            hasMessages: false,
          },
        },
        unansweredDialogsCount: 0,
      };

      setDialogState(initData, true);
    },
    [trackSearchDialog, setDialogState],
  );

  const resetFoundDialog = useCallback(() => {
    dispatch(resetFoundDialogAction());
  }, [dispatch]);

  return {
    searchDialog,
    resetFoundDialog,
  };
};
