import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { parseDialogId, useLogger } from 'modules/utils';
import { useChatScrollerActions } from 'modules/components/chat/chat-scroller-context';
import { useDialogMessagesCount, useLoadDialogMessagesApi } from 'modules/domain/messages/hooks';

import {
  SearchMessagesDirection,
  setMessagesSearchResult,
  resetMessagesSearchResult as resetMessagesSearchResultAction,
  switchSearchedMessageIndex,
  mapSearchedDialogMessagesDto,
  SearchedDialogMessage,
} from '../model';

import { useDialogMessagesSearchState } from './selectors';

const MIN_SEARCH_PHRASE_LENGTH = 3;

export const useDialogMessagesSearch = (dialogId: string) => {
  const dispatch = useDispatch();
  const { loadDialogMessages } = useLoadDialogMessagesApi(dialogId);
  const { dialogsMessages: dialogsMessagesApi } = useApi();
  const { logError } = useLogger('useDialogMessagesSearch');
  const { scrollToMessage } = useChatScrollerActions();

  const messagesCount = useDialogMessagesCount(dialogId);
  const messagesSearchState = useDialogMessagesSearchState(dialogId);

  const { animatorId, attendeeId } = parseDialogId(dialogId);

  const onSearchedMessageActiveIndexChange = useCallback(
    async (searchedMessages: SearchedDialogMessage[], activeIndex: number) => {
      const { messageIndex, messageId } = searchedMessages[activeIndex];

      const requiredMessagesCount = messageIndex - messagesCount;

      if (requiredMessagesCount > 0) {
        await loadDialogMessages(requiredMessagesCount, messagesCount);
      }

      scrollToMessage(messageId);
    },
    [loadDialogMessages, messagesCount, scrollToMessage],
  );

  const switchCurrentSearchedMessage = useCallback(
    (direction: SearchMessagesDirection) => {
      if (!messagesSearchState?.result) {
        return;
      }

      const { currentSearchedMessageIndex, result } = messagesSearchState;

      const newIndex = {
        up: currentSearchedMessageIndex + 1,
        down: currentSearchedMessageIndex - 1,
      }[direction];

      dispatch(switchSearchedMessageIndex({ dialogId, searchedMessageIndex: newIndex }));

      return onSearchedMessageActiveIndexChange(result.messages, newIndex);
    },
    [dialogId, dispatch, messagesSearchState, onSearchedMessageActiveIndexChange],
  );

  const searchMessages = useCallback(
    async (searchedPhrase: string) => {
      if (searchedPhrase.length < MIN_SEARCH_PHRASE_LENGTH) {
        dispatch(resetMessagesSearchResultAction({ dialogId }));
        return;
      }

      try {
        const resultDto = await dialogsMessagesApi.searchDialogMessages(
          animatorId,
          attendeeId,
          searchedPhrase,
        );

        const result = mapSearchedDialogMessagesDto(resultDto);

        dispatch(setMessagesSearchResult({ dialogId, searchedPhrase, result }));

        if (result.messages.length) {
          await onSearchedMessageActiveIndexChange(result.messages, 0);
        }
      } catch (error) {
        logError('Failed to search dialog messages', { error, phrase: searchedPhrase });
      }
    },
    [
      dialogsMessagesApi,
      animatorId,
      dialogId,
      dispatch,
      onSearchedMessageActiveIndexChange,
      attendeeId,
      logError,
    ],
  );

  return {
    searchMessages,
    switchCurrentSearchedMessage,
  };
};
