import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ModalNames, closeActiveModal, setActiveModal } from 'modules/domain/modals/model';

export const useModalActions = () => {
  const dispatch = useDispatch();

  const openModal = useCallback(
    (name: ModalNames, data?: any) => {
      dispatch(setActiveModal({ modalName: name, modalData: data }));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(closeActiveModal());
  }, [dispatch]);

  return {
    openModal,
    closeModal,
  };
};
