import {
  DialogNotesDto,
  DialogNotesListDto,
  DialogNotesTopic,
  DialogNoteValueDto,
} from 'modules/api/dto';
import { PatchDialogNotesList, PatchDialogNotesPayload } from 'modules/api/payload';

import { DialogNotes, DialogNotesList, DialogNoteValue } from './types';

/**
    We need an independent instance of the default_note every time, that's why the function
*/
function generateDefaultNotesList(): DialogNotesList {
  return {
    currentNews: [],
    previousRelationship: [],
    nicknames: [],
    favTopics: [],
    triggers: [],
    work: [],
    family: [],
    importantDates: [],
    habitsLifestyle: [],
    hobbiesInterests: [],
    travel: [],
    sexting: [],
    other: [],
  };
}

export function generateDialogNoteValueId(topic: DialogNotesTopic, index: number): string {
  return `${topic}_${index}_${Date.now()}`;
}

const mapDialogNoteValueDto =
  (topic: DialogNotesTopic) =>
  (dto: DialogNoteValueDto, index: number): DialogNoteValue => {
    return {
      id: generateDialogNoteValueId(topic, index),
      isAutoChanged: dto.isAutoChanged,
      text: dto.text,
    };
  };

function mapNotesListDto(dto: DialogNotesListDto): DialogNotesList {
  const defaultNotesList = generateDefaultNotesList();
  const topics = Object.keys(defaultNotesList) as DialogNotesTopic[];

  const clientNotes = topics.reduce((result, topic) => {
    result[topic] = dto[topic]?.values.map(mapDialogNoteValueDto(topic)) || defaultNotesList[topic];

    return result;
  }, {} as DialogNotesList);

  return clientNotes;
}

export function mapDialogNotesDtoToNotes(dto: DialogNotesDto): DialogNotes {
  return {
    animator: mapNotesListDto(dto.animator.note),
    attendee: mapNotesListDto(dto.user.note),
  };
}

const transformNotesListToPatchList = (notes: DialogNotesList): PatchDialogNotesList =>
  Object.entries(notes).reduce((result, [key, values]) => {
    result[key as DialogNotesTopic] = {
      values: values.map(v => v.text).filter(v => v),
    };

    return result;
  }, {} as PatchDialogNotesList);

export const mapDialogNotesToPatchPayload = (
  animatorId: string,
  attendeeId: string,
  notes: DialogNotes,
): PatchDialogNotesPayload => {
  return {
    animator: {
      id: animatorId,
      note: transformNotesListToPatchList(notes.animator),
    },
    user: {
      id: attendeeId,
      note: transformNotesListToPatchList(notes.attendee),
    },
  };
};
