import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';

import { AudioMessagesModelState, AudioMessageTemplate } from './types';

type SetAudioMessagesAction = PayloadAction<{
  dialogId: string;
  audioMessages: AudioMessageTemplate[];
}>;

const initialState: AudioMessagesModelState = {};

export const audioMessagesModel = createSlice({
  name: 'audioMessages',
  initialState,
  reducers: {
    setAudioMessages: (state, { payload: { dialogId, audioMessages } }: SetAudioMessagesAction) => {
      state[dialogId] = audioMessages;
    },
    resetAudioMessages: () => initialState,
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getAudioMessagesState: (state, dialogId: string) => state[dialogId],
  },
});
