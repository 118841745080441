import { getIsExplicitPossibleForClient } from 'modules/domain/preferences/model';
import { useRootSelector } from 'modules/hooks/redux';

export const useIsExplicitPossibleForClient = (dialogId: string) => {
  const isExplicitPossibleForClient = useRootSelector(state =>
    getIsExplicitPossibleForClient(state, dialogId),
  );

  return isExplicitPossibleForClient;
};
