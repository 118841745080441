import { getAnimatorPreferences } from 'modules/domain/preferences/model';
import { useRootSelector } from 'modules/hooks/redux';

export const useDialogAnimatorPreferences = (dialogId: string) => {
  const dialogAnimatorPreferences = useRootSelector(state =>
    getAnimatorPreferences(state, dialogId),
  );

  return dialogAnimatorPreferences;
};
