import React, { useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikConfig } from 'formik';

import { Button } from 'modules/components/common/button/base';
import { TextInput } from 'modules/components/common/text-input';
import { testId } from 'modules/utils';
import { FastAnswersList } from 'modules/domain/fast-answers/model';

import styles from './index.module.scss';
import { FastAnswersFormErrorValues, FastAnswersFormValues } from './types';

type Props = {
  fastAnswers: FastAnswersList;
  validate: (values: FastAnswersFormValues) => FastAnswersFormErrorValues;
  onSubmit: FormikConfig<FastAnswersFormValues>['onSubmit'];
  onDismiss: () => void;
};

export default (props: Props) => {
  const { fastAnswers, validate, onSubmit, onDismiss } = props;

  const initialValues = useMemo(
    () =>
      fastAnswers.reduce((result, fastAnswer) => {
        result[fastAnswer.shortcut] = fastAnswer.phrase;
        return result;
      }, {} as FastAnswersFormValues),
    [fastAnswers],
  );

  return (
    <div className={styles.container}>
      <Formik<FastAnswersFormValues>
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form {...testId('templates-modal-fast-answers-container')}>
            {fastAnswers.map(item => {
              return (
                <div key={item.shortcut} className={styles.inputWrapper}>
                  <span className={styles.inputLabel}>Ctrl + {item.shortcutDigit}</span>
                  <div className={styles.inputContainer}>
                    <Field
                      type="text"
                      name={item.shortcut}
                      placeholder="Type your message..."
                      backgroundTheme="light"
                      className={styles.input}
                      component={TextInput}
                    />
                    <ErrorMessage name={item.shortcut} component="span" className={styles.error} />
                  </div>
                </div>
              );
            })}
            <div className={styles.row}>
              <Button
                {...testId('templates-modal-cancel-button')}
                type="button"
                size="md"
                disabled={isSubmitting}
                onClick={onDismiss}
              >
                Cancel
              </Button>
              <Button
                {...testId('templates-modal-save-button')}
                type="submit"
                size="md"
                theme="green"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
