import { useCallback } from 'react';

import { useSendDialogMessageApi } from 'modules/domain/messages/hooks';
import { MessageType } from 'modules/domain/messages/model';
import { useApi } from 'modules/api';
import { createMessageReference, useLogger } from 'modules/utils';
import { GiftDto } from 'modules/api/dto';

import { getGiftReference, getGiftsPriceLimitFromDto } from '../helpers';

export const useGiftsApi = (dialogId: string) => {
  const { gifts: giftsApi } = useApi();
  const { logError } = useLogger('useGiftsApi');
  const sendDialogMessage = useSendDialogMessageApi(dialogId);

  const getGifts = useCallback(
    async (attendeeId: string) => {
      try {
        return await giftsApi.getGifts(attendeeId);
      } catch (error) {
        logError('Fetch gifts error', { error, attendeeId });

        return [];
      }
    },
    [giftsApi, logError],
  );

  const getGiftsPriceLimit = useCallback(
    async (animatorId: string, attendeeId: string) => {
      try {
        const response = await giftsApi.getGiftsAllowedData(animatorId, attendeeId);

        return getGiftsPriceLimitFromDto(response);
      } catch (error) {
        logError('fetch gifts allowed data error', { error, animatorId, attendeeId });

        return getGiftsPriceLimitFromDto({ result: false });
      }
    },
    [giftsApi, logError],
  );

  const sendGift = useCallback(
    async (gift: GiftDto, copilotUsed: boolean) => {
      try {
        await sendDialogMessage({
          reference: createMessageReference(MessageType.GIFT, getGiftReference(gift), true),
          meta: { gift: gift.giftId },
          type: MessageType.GIFT,
          copilotUsed,
        });

        return true;
      } catch (error) {
        logError('Send gift error', { error, gift });

        return false;
      }
    },
    [sendDialogMessage, logError],
  );

  return { getGifts, getGiftsPriceLimit, sendGift };
};
