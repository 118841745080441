import { fastAnswersModel } from './model';

const { reducer, reducerPath, actions, selectors } = fastAnswersModel;

export const { setFastAnswers } = actions;
export const { getFastAnswers } = selectors;
export { reducer, reducerPath as namespace };

export type {
  FastAnswer,
  FastAnswersList,
  FastAnswerShortcut,
  FastAnswersModelState,
} from './types';
export { mapFastAnswersDtoToList, mapFastAnswersListToDto } from './adapter';
