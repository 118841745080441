import { useRootSelector } from 'modules/hooks/redux';
import { getDialogAllMessagesLoaded } from 'modules/domain/messages/model';

export const useDialogAllMessagesLoaded = (dialogId: string) => {
  const dialogAllMessagesLoaded = useRootSelector(state =>
    getDialogAllMessagesLoaded(state, dialogId),
  );

  return dialogAllMessagesLoaded;
};
