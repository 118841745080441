import { useEffect } from 'react';

import { useFastAnswersData } from './selectors';
import { useFastAnswersApi } from './use-fast-answers-api';

export const useOperatorFastAnswers = () => {
  const { loadOperatorFastAnswers, updateOperatorFastAnswers } = useFastAnswersApi();
  const fastAnswers = useFastAnswersData();

  useEffect(() => {
    if (!fastAnswers) {
      loadOperatorFastAnswers();
    }
  }, [fastAnswers, loadOperatorFastAnswers]);

  return {
    fastAnswers,
    updateOperatorFastAnswers,
  };
};
