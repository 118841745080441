import { useDialogMessagesAllowed } from 'modules/domain/messages/hooks';

import { checkMediaAllowed } from '../helpers';

export const useDialogMediaAllowed = (dialogId: string) => {
  const dialogMessagesAllowed = useDialogMessagesAllowed(dialogId);
  const dialogMediaAllowed = checkMediaAllowed(dialogMessagesAllowed);

  return dialogMediaAllowed;
};
