import { useEffect } from 'react';

import {
  useDialogUnansweredUnpaidState,
  useLoadDialogMessagesApi,
} from 'modules/domain/messages/hooks';

export const useUnansweredUnpaidMessageLoader = (dialogId: string) => {
  const unansweredUnpaid = useDialogUnansweredUnpaidState(dialogId);

  const { loadUnansweredUnpaidDialogMessage } = useLoadDialogMessagesApi(dialogId);

  const needToLoadUnansweredUnpaidMessage =
    unansweredUnpaid?.hasMessages && !unansweredUnpaid.messageLoaded;

  useEffect(() => {
    if (needToLoadUnansweredUnpaidMessage) {
      loadUnansweredUnpaidDialogMessage();
    }
  }, [loadUnansweredUnpaidDialogMessage, needToLoadUnansweredUnpaidMessage]);
};
