import { ActionReducerMapBuilder, Draft, createAction } from '@reduxjs/toolkit';

export enum AuthActions {
  Logout = 'auth.logout',
}

export const authLogout = createAction(AuthActions.Logout);

export const addLogoutReducer =
  <State>(reducer: (state: Draft<State>) => State) =>
  (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(authLogout, reducer);
  };
