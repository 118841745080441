import { dialogNotesModel } from './model';

const { reducer, reducerPath, actions, selectors } = dialogNotesModel;

export const { setDialogNotes } = actions;
export const { getDialogNotes } = selectors;
export { reducer, reducerPath as namespace };

export type {
  DialogNotes,
  DialogNoteValue,
  DialogNotesList,
  DialogNotesModelState,
  DialogNotesTopic,
} from './types';

export {
  mapDialogNotesDtoToNotes,
  mapDialogNotesToPatchPayload,
  generateDialogNoteValueId,
} from './adapter';
