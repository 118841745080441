import React, { memo, useRef, useEffect, useState } from 'react';

import { CrossIcon } from 'modules/components/icon/cross';
import { useModalActions } from 'modules/domain/modals/hooks';
import { RoundButton } from 'modules/components/common/button/round';
import cameraIcon from 'images/icon-camera.svg';

import { WebcamSection } from './webcam-section';
import { WebcamModalStatus } from './types';
import styles from './index.module.scss';

export const WebcamMediaModal = memo(() => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const [modalStatus, setModalStatus] = useState(WebcamModalStatus.Initial);

  const { closeModal } = useModalActions();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        if (videoRef.current) {
          setModalStatus(WebcamModalStatus.VideoRecording);

          streamRef.current = stream;
          videoRef.current.srcObject = stream;

          videoRef.current.play();
        }
      })
      .catch(() => {
        setModalStatus(WebcamModalStatus.AccessDenied);
      });

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const cameraEnabled =
    modalStatus === WebcamModalStatus.VideoRecording ||
    modalStatus === WebcamModalStatus.PhotoCaptured;
  const videoRecordingEnabled = modalStatus === WebcamModalStatus.VideoRecording;

  return (
    <div className={styles.container}>
      {modalStatus === WebcamModalStatus.Initial && (
        <div className={styles.accessContainer}>
          <img src={cameraIcon} alt="" />
          <span>
            Please allow access to your
            <br />
            webcam.
          </span>
        </div>
      )}

      {modalStatus === WebcamModalStatus.AccessDenied && (
        <div className={styles.accessDeniedContainer}>
          <img src={cameraIcon} alt="" />
          <span>
            Allow access the webcam in your
            <br />
            browser settings.
          </span>

          <RoundButton
            size="xs"
            theme="transparent"
            className={styles.accessContainerCloseButton}
            onClick={closeModal}
          >
            <CrossIcon />
          </RoundButton>
        </div>
      )}

      <WebcamSection
        cameraEnabled={cameraEnabled}
        closeModal={closeModal}
        setModalStatus={setModalStatus}
        videoRecordingEnabled={videoRecordingEnabled}
        videoRef={videoRef}
      />

      {cameraEnabled && (
        <RoundButton onClick={closeModal} size="md" className={styles.closeButton}>
          <CrossIcon />
        </RoundButton>
      )}
    </div>
  );
});
