import { dialogMessagesModel } from './model';

const { reducer, reducerPath, actions, selectors } = dialogMessagesModel;

export const {
  initDialogMessagesState,
  insertDialogMessages,
  setDialogMessageSent,
  setUnansweredUnpaidMessageLoaded,
  setMessageSendingFailed,
  setUnansweredUnpaidMessageAnswered,
  setUploadMessageMediaStart,
  setUploadMessageMediaProgress,
  setUploadMessageMediaFinish,
} = actions;
export const {
  getDialogMessages,
  getDialogSomeMessagesLoaded,
  getDialogAllMessagesLoaded,
  getDialogUnansweredUnpaidState,
  getMediaMessageUploadState,
  getDialogMessagesAllowed,
} = selectors;
export { reducer, reducerPath as namespace };

export type {
  DialogMessage,
  BasenameContent,
  IcebreakerContent,
  GiftMessageContent,
  LocalPhotoContent,
  AudioMessageContent,
  DialogMessageContent,
  DialogMessagesInitState,
  DialogMessagesModelState,
  SendDialogMessageBaseData,
  SendDialogMessageData,
} from './types';
export { MessageType, DialogMessagesAllowed } from './types';

export {
  mapDialogDtoToMessagesInitState,
  mapDialogMessagesDto,
  mapDialogMessageDto,
} from './adapter';
