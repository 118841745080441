import { AnimatorPreferencesDto, DialogDto, DialogNotesOldDto } from 'modules/api/dto';

import { AnimatorPreferences, DialogExplicityPreferences } from './types';

export const mapDialogAnimatorPreferencesDto = (
  dto: AnimatorPreferencesDto,
): AnimatorPreferences => {
  return {
    character: dto.character,
    favouriteEmojis: dto.favouriteEmojis,
    style: dto.style,
    typing: dto.typing,
  };
};

export const getAnimatorStoryToldFromDto = (notesOld: DialogNotesOldDto): boolean => {
  const animatorStoryTold = notesOld.storyTold
    ? (JSON.parse(notesOld.storyTold) as boolean)
    : false;

  return animatorStoryTold;
};

export const mapDialogDtoToExplicityPreferences = (dto: DialogDto): DialogExplicityPreferences => {
  return {
    isExplicit: dto.IsExplicit || false,
    isExplicitPossibleForClient: dto.ExplicitPossibleForClient || false,
  };
};
