import { useDialogMessagesAllowed } from 'modules/domain/messages/hooks';

import { checkGiftsAllowed } from '../helpers';

export const useGiftsAllowed = (dialogId: string) => {
  const dialogMessagesAllowed = useDialogMessagesAllowed(dialogId);

  const giftsAllowed = checkGiftsAllowed(dialogMessagesAllowed);

  return giftsAllowed;
};
