import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import { MediaModelState, DialogMediaState, UserMedia } from './types';

type SetUserPhotosAction = PayloadAction<{
  userId: string;
  photos: UserMedia[];
}>;
type SetDialogMediaStateAction = ActionWithDialogId<{
  media: DialogMediaState;
}>;

const initialState: MediaModelState = {
  userPhotos: {},
  dialogMedia: {},
};

export const mediaModel = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setUserPhotos: (state, action: SetUserPhotosAction) => {
      const { userId, photos } = action.payload;
      state.userPhotos[userId] = photos;
    },
    setDialogMediaState: (state, action: SetDialogMediaStateAction) => {
      const { dialogId, media } = action.payload;
      state.dialogMedia[dialogId] = media;
    },
    resetDialogMediaState: state => {
      state.dialogMedia = initialState.dialogMedia;
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getDialogMediaState: (state, dialogId: string) => state.dialogMedia[dialogId],
    getUserPhotos: (state, userId: string) => state.userPhotos[userId],
  },
});
