import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getDialogId, parseDialogId, useLogger } from 'modules/utils';
import { useApi } from 'modules/api';

import {
  DialogNotes,
  mapDialogNotesDtoToNotes,
  mapDialogNotesToPatchPayload,
  setDialogNotes,
} from '../model';

export const useDialogNotesApi = () => {
  const dispatch = useDispatch();
  const { logError } = useLogger('useDialogNotesApi');
  const { dialogsNotes: dialogsNotesApi } = useApi();

  const loadDialogNotes = useCallback(
    async (animatorId: string, attendeeId: string) => {
      const dialogId = getDialogId(animatorId, attendeeId);

      try {
        const notesDto = await dialogsNotesApi.getDialogNotes(animatorId, attendeeId);
        const notes = mapDialogNotesDtoToNotes(notesDto);
        dispatch(setDialogNotes({ dialogId, notes }));
      } catch (error) {
        logError('fetchDialogNotes error', { dialogId, error });
      }
    },
    [dispatch, dialogsNotesApi, logError],
  );

  const updateDialogNotes = useCallback(
    async (dialogId: string, notes: DialogNotes) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);
      const payload = mapDialogNotesToPatchPayload(animatorId, attendeeId, notes);

      try {
        await dialogsNotesApi.patchDialogNotes(animatorId, attendeeId, payload);

        dispatch(setDialogNotes({ dialogId, notes }));
      } catch (error) {
        logError('saveDialogNotes error', {
          animatorId,
          attendeeId,
          payload,
          error,
        });
      }
    },
    [dispatch, dialogsNotesApi, logError],
  );

  return {
    loadDialogNotes,
    updateDialogNotes,
  };
};
