import React, { memo } from 'react';

import { useDialogAvailableMedia } from 'modules/domain/media/hooks';
import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { useOperatorCommission } from 'modules/domain/operator/hooks';
import { useActiveModalData } from 'modules/domain/modals/hooks';
import { MediaSelectorModalData } from 'modules/constants';

import { MediaItem } from './media-item';
import { useSendDialogMedia } from './use-send-dialog-media';
import styles from './index.module.scss';

export const MediaSelectorModal = memo(() => {
  const dialogId = useCurrentDialogId();
  const operatorCommission = useOperatorCommission();
  const { parent } = useActiveModalData<MediaSelectorModalData>();

  const { sendDialogMedia, messageSending } = useSendDialogMedia(dialogId);
  const media = useDialogAvailableMedia(dialogId);

  const onMediaSelect = (baseUrl: string) => {
    const lastMediaLength = media?.length || 0;
    sendDialogMedia(baseUrl, lastMediaLength, parent === 'copilot');
  };

  return (
    <div className={styles.container}>
      <h4>Send a Photo</h4>
      <p className={styles.text}>
        Send one of these photos to a client once in a while and get ${operatorCommission} if the
        photo is viewed. If a client&apos;s reply is photo you will also get ${operatorCommission}{' '}
        for it.
      </p>

      <div className={styles.list}>
        {!media?.length ? (
          <p>There are no photos</p>
        ) : (
          media.map(({ baseUrl, basename }) => (
            <MediaItem
              key={basename}
              id={basename}
              baseUrl={baseUrl}
              disabled={messageSending}
              onItemClick={() => onMediaSelect(baseUrl)}
            />
          ))
        )}
      </div>
    </div>
  );
});
