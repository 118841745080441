import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { FastAnswer, FastAnswersList } from 'modules/domain/fast-answers/model';
import { testId } from 'modules/utils';

import { FastAnswersBaseButton } from '../base-button';

import { FastAnswersSliderSwitchButton } from './switch-button';
import styles from './index.module.scss';

type Props = {
  fastAnswers: FastAnswersList;
  onFastAnswerClick: (fastAnswer: FastAnswer) => void;
};

const PAGE_SIZE = 5;

export const FastAnswersSlider = (props: Props) => {
  const { fastAnswers, onFastAnswerClick } = props;

  const [page, setPage] = useState(1);

  const pageItems = useMemo(() => {
    if (fastAnswers.length <= PAGE_SIZE) {
      return fastAnswers;
    }

    const startIndex = (page - 1) * PAGE_SIZE;
    return fastAnswers.slice(startIndex, startIndex + PAGE_SIZE);
  }, [fastAnswers, page]);

  const pagingAvailable = fastAnswers.length > PAGE_SIZE;

  useEffect(() => {
    if (!pagingAvailable) {
      setPage(1);
    }
  }, [pagingAvailable]);

  const handleFastAnswerClick = (fastAnswer: FastAnswer) => () => onFastAnswerClick(fastAnswer);

  const handleSwitchBtnClick = () => {
    setPage(prevPage => (prevPage === 1 ? 2 : 1));
  };

  return (
    <>
      <div className={styles.container}>
        {pageItems.map((item: FastAnswer) => {
          return (
            <FastAnswersBaseButton
              {...testId(`footer-shortcut-${item.shortcut}`)}
              key={item.shortcut}
              className={classNames(styles.itemWrapper, {
                [styles.itemWrapperStretch]: pageItems.length === PAGE_SIZE,
              })}
              onClick={handleFastAnswerClick(item)}
            >
              <div className={styles.itemCaption}>Ctrl + {item.shortcutDigit}</div>
              <div className={styles.itemTitle}>{item.phrase}</div>
            </FastAnswersBaseButton>
          );
        })}
      </div>
      {pagingAvailable && (
        <FastAnswersSliderSwitchButton
          direction={page === 1 ? 'next' : 'prev'}
          onClick={handleSwitchBtnClick}
        />
      )}
    </>
  );
};
