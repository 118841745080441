import { AudioMessageTemplateDto } from 'modules/api/dto';

import { AudioMessageTemplate } from './types';

export const mapAudioMessageTemplateDto = (dto: AudioMessageTemplateDto): AudioMessageTemplate => ({
  id: dto.id,
  basename: dto.baseName,
  text: dto.text,
  topic: dto.topic,
});
