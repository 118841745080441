import { useDialogMessagesSearchState } from 'modules/domain/messages-search/hooks';

export const useSearchResultHighlight = (dialogId: string, messageId: number) => {
  const {
    result,
    searchedPhrase,
    currentSearchedMessageIndex = 0,
  } = useDialogMessagesSearchState(dialogId) || {};
  const searchedMessages = result?.messages || [];
  const currentSearchedMessage = searchedMessages[currentSearchedMessageIndex];

  const isSearchedMessage = searchedMessages.some(m => m.messageId === messageId);
  const highlightedText = isSearchedMessage ? searchedPhrase : undefined;
  const isInFocus =
    !!currentSearchedMessage?.messageId && currentSearchedMessage.messageId === messageId;

  return { highlightedText, isInFocus };
};
