export enum DialogQueueType {
  Grey = 2,
  /**
   * Has unanswered unpaid messages
   */
  Blue = 3,
}

export type DialogDto = {
  Id: number;
  Client: {
    SiteId: string;
  };
  Animator: {
    SiteId: string;
  };
  Operator: {
    SiteId: string;
  };
  IsExplicit?: boolean;
  ExplicitPossibleForClient?: boolean;
  QueueTypeId: DialogQueueType;
  ChatStartTimestamp?: string;
};

export type DialogsResponseDto = {
  Dialogs: DialogDto[];
  Unanswered: {
    Count: number;
  };
};

export type DayWithMessagesDto = {
  count: number;
  date: string;
  idMax: number;
  idMin: number;
};

export type DaysWithMessagesResponseDto = {
  dates: DayWithMessagesDto[];
};
