import { useMemo } from 'react';

import { useRootSelector } from 'modules/hooks/redux';
import { getDialogMessages } from 'modules/domain/messages/model';

export const useDialogMessages = (dialogId: string) => {
  const stateMessages = useRootSelector(state => getDialogMessages(state, dialogId));

  const dialogMessages = useMemo(() => stateMessages || [], [stateMessages]);

  return dialogMessages;
};
