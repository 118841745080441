import { useMemo } from 'react';

import { DialogMessage } from 'modules/domain/messages/model';
import { useUserField } from 'modules/domain/users/hooks';
import { DialogExtendedMessage } from 'modules/components/chat/message-row/types';

import { checkMessagesNotSameDay } from './check-messages-not-same-day';

/**
 * Important note: messages have to be sorted in descending order first
 */
export const mapMessagesToExtendedMessages = (
  baseMessages: DialogMessage[],
  animatorTimezone: number,
): DialogExtendedMessage[] => {
  let onboardingAttached = false;

  return baseMessages.map((message, index) => {
    const prevMessage: DialogMessage | null = baseMessages[index + 1];

    const firstMessageOfTheDay = checkMessagesNotSameDay(message, prevMessage, animatorTimezone);
    const attachOnboarding = !onboardingAttached && firstMessageOfTheDay;

    if (attachOnboarding) {
      onboardingAttached = true;
    }

    return {
      ...message,
      attachOnboarding,
      firstMessageOfTheDay,
    };
  });
};

export const useDialogExtendedMessages = (messages: DialogMessage[], animatorId: string) => {
  const animatorTimezone = useUserField(animatorId, 'timezone') || 0;

  const extendedMessages = useMemo(
    () => mapMessagesToExtendedMessages(messages, animatorTimezone),
    [messages, animatorTimezone],
  );

  return extendedMessages;
};
