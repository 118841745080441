import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth';
import { SendDialogMessageData } from 'modules/domain/messages/model';
import { useDialogLatestMessage } from 'modules/domain/messages/hooks';
import { getDialogId } from 'modules/utils';
import { AutomationProductName } from 'modules/api/dto';

import { useCopilotDataState, useCopilotLastUsedData } from '../selectors';

import {
  transformMessageTypeForCopilotAnalytics,
  getCopilotUsage,
  transformOptionsForCopilotAnalytics,
  checkCopilotLoaded,
} from './helpers';

export const useCopilotAnalytics = (animatorId: string, attendeeId: string) => {
  const operatorId = useOperatorId();
  const { annals } = useApi();

  const lastMessage = useDialogLatestMessage(getDialogId(animatorId, attendeeId));
  const actualCopilotData = useCopilotDataState(animatorId, attendeeId, lastMessage?.id || 0);
  const copilotLastUsedData = useCopilotLastUsedData();

  const trackCopilotUsage = useCallback(
    (analyticsData: { message: string; type: string; options: string[]; botName?: string }) => {
      const payload = {
        userId: attendeeId,
        animatorId,
        operatorId,
        ...analyticsData,
      };

      return annals.send(operatorId, 'copilot-data', payload);
    },
    [annals, operatorId, animatorId, attendeeId],
  );

  const trackCopilotUsageAndInjectMeta = useCallback(
    (messageData: SendDialogMessageData) => {
      if (!copilotLastUsedData || !checkCopilotLoaded(actualCopilotData)) {
        return;
      }

      const { type: messageType, copilotUsed, text = '' } = messageData;

      const messageTypeForCopilot = transformMessageTypeForCopilotAnalytics(
        messageType,
        copilotUsed,
      );

      const usage = getCopilotUsage(messageTypeForCopilot, copilotLastUsedData);

      const { options = [], specialOption, botName } = copilotLastUsedData || actualCopilotData;

      const optionsTexts = transformOptionsForCopilotAnalytics(options, specialOption);

      if (!messageData.meta) {
        messageData.meta = {};
      }

      messageData.meta.automation = {
        product: AutomationProductName.Copilot,
        bot: botName,
        usage,
      };

      trackCopilotUsage({
        message: text,
        type: messageTypeForCopilot,
        options: optionsTexts,
        botName,
      });
    },
    [trackCopilotUsage, actualCopilotData, copilotLastUsedData],
  );

  return { trackCopilotUsageAndInjectMeta };
};
