import { isEqual, unionBy } from 'lodash';

import { DialogMessage, DialogMessagesState } from '../types';

export const insertDialogMessages = (
  messagesState: DialogMessagesState,
  newMessages: DialogMessage[],
) => {
  const updatedMessages = unionBy(
    // the order of arrays is important here as we took the `newMessages` array
    // as the base array to union the old state with.
    // Union compares array elements by the `tag` and if the both arrays have message with the same tag
    // It will keep the message from the first array
    newMessages,
    messagesState.messages,
    'tag',
  );
  updatedMessages.sort((a, b) => b.timestamp - a.timestamp);

  if (!isEqual(messagesState.messages, updatedMessages)) {
    messagesState.messages = updatedMessages;
  }
};
