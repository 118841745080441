import React, { useState, Ref } from 'react';
import cn from 'classnames';

import purpurPickerIcon from 'images/purpur-picker-icon.svg';
import purpurLogo from 'images/purpur-logo-big.svg';
import purpurImageGeneral from 'images/purpur-image-general.svg';
import purpurImageErotic from 'images/purpur-image-erotic.svg';
import { testId } from 'modules/utils';
import { useClickOutside } from 'modules/domain/common/hooks';
import { IconButton } from 'modules/components/common/button';
import { IcebreakerType } from 'modules/api/dto';
import { MediaPickerParent } from 'modules/constants';

import { useSendIcebreakerMessage } from './use-send-icebreaker-message';
import styles from './index.module.css';

type Props = {
  dialogId: string;
  btnRef?: Ref<HTMLButtonElement>;
  btnClassName?: string;
  modalClassName?: string;
  parent: MediaPickerParent;
};

export const IcebreakerPicker = (props: Props) => {
  const { dialogId, btnRef, btnClassName, modalClassName, parent } = props;

  const [pickerOpen, setPickerOpen] = useState(false);

  const sendIcebreakerMessage = useSendIcebreakerMessage(dialogId);

  const pickerModalRef = useClickOutside<HTMLDivElement>({
    condition: pickerOpen,
    action: () => setPickerOpen(false),
  });

  const onPickIcebreaker = (icebreakerType: IcebreakerType) => {
    setPickerOpen(false);

    sendIcebreakerMessage(icebreakerType, parent === 'copilot');
  };

  return (
    <div className={styles.pickerContainer}>
      {pickerOpen && (
        <div className={cn(styles.tooltip, modalClassName)} ref={pickerModalRef}>
          <div className={styles.title}>
            Let&apos;s play <img src={purpurLogo} alt="purpur" />
          </div>
          <div className={styles.subtitle}>
            Send a PurPur Card to ask your partner a question and answer yourself once you get their
            reply
          </div>
          <div className={styles.images}>
            <div className={styles.eroticCard} onClick={() => onPickIcebreaker('erotic')}>
              <img
                className={cn(styles.purpurImage, styles.eroticImage)}
                src={purpurImageErotic}
                alt="erotic"
              />
            </div>
            <div className={styles.generalCard} onClick={() => onPickIcebreaker('general')}>
              <img
                className={cn(styles.purpurImage, styles.generalImage)}
                src={purpurImageGeneral}
                alt="general"
              />
            </div>
          </div>
          <div className={styles.legend}>
            <div>
              Spicy
              <br />
              questions
            </div>
            <div>
              Everything
              <br />
              questions
            </div>
          </div>
        </div>
      )}
      <IconButton
        ref={btnRef}
        className={btnClassName}
        {...testId('icebreaker-picker')}
        onClick={() => setPickerOpen(pickerOpen => !pickerOpen)}
      >
        <img src={purpurPickerIcon} />
      </IconButton>
    </div>
  );
};
