import { useCallback } from 'react';

import { useLogger } from 'modules/utils';
import { useDispatchAction } from 'modules/hooks/redux';

import {
  CopilotData,
  CopilotOption,
  setCopilotTextOptionSelected as setCopilotTextOptionSelectedAction,
} from '../model';

import { useCopilotApi } from './use-copilot-api';

export function useCopilotHandlers(animatorId: string, attendeeId: string) {
  const { log } = useLogger('useCopilotHandlers');

  const { notifyAboutTheTrap: apiNotifyAboutTheTrap } = useCopilotApi();
  const setCopilotTextOptionSelected = useDispatchAction(setCopilotTextOptionSelectedAction);

  const selectCopilotTextOption = useCallback(
    (option: CopilotOption, copilotData: CopilotData) => {
      log('info', 'Copilot option select', { option, animatorId, attendeeId });
      setCopilotTextOptionSelected({ optionText: option.text, data: copilotData });
    },
    [setCopilotTextOptionSelected, log, animatorId, attendeeId],
  );

  const notifyAboutTheTrap = useCallback(
    (option: CopilotOption) => {
      apiNotifyAboutTheTrap(animatorId, attendeeId, option);
    },
    [apiNotifyAboutTheTrap, animatorId, attendeeId],
  );

  return {
    selectCopilotTextOption,
    notifyAboutTheTrap,
  };
}
