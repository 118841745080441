import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { InitDialogStateData, setCurrentDialog, setFoundDialog } from 'modules/domain/dialog/model';
import { setDialogExplicityPreferences } from 'modules/domain/preferences/model';
import { initDialogMessagesState } from 'modules/domain/messages/model';

export const useSetDialogState = () => {
  const dispatch = useDispatch();

  const setDialogState = useCallback(
    (initData: InitDialogStateData, searchMode?: boolean) => {
      const { dialog, messagesInitState, explicityPreferences, unansweredDialogsCount } = initData;
      const dialogId = dialog.id;

      if (searchMode) {
        dispatch(setFoundDialog({ dialog }));
      } else {
        dispatch(setCurrentDialog({ dialog, unansweredDialogsCount }));
      }
      dispatch(setDialogExplicityPreferences({ dialogId, explicityPreferences }));
      dispatch(initDialogMessagesState({ dialogId, messagesInitState }));
    },
    [dispatch],
  );

  return setDialogState;
};
