import React, { memo } from 'react';

import { useUnansweredDialogsCount } from 'modules/domain/dialog/hooks';

import { ExtendedTimerView } from './view';

export const ExtendedTimer = memo(() => {
  const unansweredDialogsCount = useUnansweredDialogsCount();

  if (!unansweredDialogsCount) {
    return null;
  }

  return <ExtendedTimerView />;
});
