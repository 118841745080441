import { mediaModel } from './model';

const { reducer, reducerPath, actions, selectors } = mediaModel;

export const { setDialogMediaState, setUserPhotos, resetDialogMediaState } = actions;
export const { getDialogMediaState, getUserPhotos } = selectors;
export { reducer, reducerPath as namespace };

export type { UserMedia, DialogMedia, DialogMediaState, MediaModelState } from './types';
export { mapChatMediaListDto, mapSentMediaListDto, mapUserMediaListDto } from './adapter';
