import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import classNames from 'classnames';

import { Image } from 'modules/components/image';
import { ImageOverlay } from 'modules/components/image-overlay';
import { testId } from 'modules/utils';
import { useMediaMessageUploadState } from 'modules/domain/messages/hooks';

import styles from './index.module.scss';

type Props = {
  dialogId: string;
  localSourceUri: string;
  messageTag: string;
};

export const LocalImageMessage = (props: Props) => {
  const { localSourceUri, messageTag, dialogId } = props;

  const uploadState = useMediaMessageUploadState(dialogId, messageTag);

  return (
    <div className={styles.container}>
      <ImageOverlay
        className={classNames('chat-image-message', styles.overlayContainer)}
        src={localSourceUri}
      >
        <Image className={styles.image} a11yLabel="message-image" src={localSourceUri} alt={''} />
      </ImageOverlay>

      {uploadState && (
        <div className={styles.progressContainer}>
          <CircularProgressbar
            {...testId('message-image-progress')}
            value={uploadState.progress}
            counterClockwise
            strokeWidth={3}
            styles={buildStyles({
              trailColor: 'transparent',
              pathColor: '#fff',
            })}
          />
        </div>
      )}
    </div>
  );
};
