import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  setInputFocused as setInputFocusedAction,
  setInputText as setInputTextAction,
  resetInputText as resetInputTextAction,
  setFastAnswerSelected as setFastAnswerSelectedAction,
} from 'modules/domain/chat-input/model';
import { FastAnswer } from 'modules/domain/fast-answers/model';

export const useChatInputActions = () => {
  const dispatch = useDispatch();

  const setInputText = useCallback(
    (inputText: string, append?: boolean) => {
      dispatch(setInputTextAction({ inputText, append }));
    },
    [dispatch],
  );

  const resetInputText = useCallback(() => {
    dispatch(resetInputTextAction());
  }, [dispatch]);

  const setInputFocused = useCallback(
    (focused: boolean) => {
      dispatch(setInputFocusedAction(focused));
    },
    [dispatch],
  );

  const setFastAnswerSelected = useCallback(
    (message: string, fastAnswer: FastAnswer) => {
      dispatch(setFastAnswerSelectedAction({ message, fastAnswer }));
    },
    [dispatch],
  );

  return {
    setInputText,
    resetInputText,
    setInputFocused,
    setFastAnswerSelected,
  };
};
