import { useMemo } from 'react';

import { useCurrentDialog } from './use-current-dialog';

export const useCurrentDialogUsersIds = () => {
  const currentDialog = useCurrentDialog();

  const ids = useMemo(
    () => ({
      animatorId: currentDialog?.animatorId || '',
      attendeeId: currentDialog?.attendeeId || '',
    }),
    [currentDialog],
  );

  return ids;
};
