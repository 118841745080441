import { useRootSelector } from 'modules/hooks/redux';
import { getDialogAnimatorStoryTold } from 'modules/domain/preferences/model';

export const useDialogAnimatorStoryTold = (dialogId: string) => {
  const dialogAnimatorStoryTold = useRootSelector(state =>
    getDialogAnimatorStoryTold(state, dialogId),
  );

  return dialogAnimatorStoryTold;
};
