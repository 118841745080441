import { utcDate } from 'modules/utils';
import { DialogMessage } from 'modules/domain/messages/model';

export function checkMessagesNotSameDay(
  message1: DialogMessage,
  message2: DialogMessage | null,
  timezone: number,
) {
  if (!message2) {
    return true;
  }
  const date1 = utcDate(message1.timestamp).utcOffset(timezone);
  const date2 = utcDate(message2.timestamp).utcOffset(timezone);

  return !date1.isSame(date2, 'day');
}
