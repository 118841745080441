import { DialogMessageDto } from 'modules/api/dto';
import { generateMessageTag } from 'modules/utils';

export const createBaseMessageDraft = (
  animatorId: string,
  attendeeId: string,
  text = '',
): Omit<DialogMessageDto, 'meta'> => ({
  id: Date.now(),
  tag: generateMessageTag(animatorId),
  sender: animatorId,
  recipient: attendeeId,
  text,
  timestamp: new Date().getTime(),
});
