import React, { memo, useCallback } from 'react';

import { useModalActions } from 'modules/domain/modals/hooks';
import { useOperatorFastAnswers } from 'modules/domain/fast-answers/hooks';
import { FastAnswersList } from 'modules/domain/fast-answers/model';
import { toast } from 'modules/utils';

import { FastAnswerModalForm } from './form';
import styles from './index.module.scss';

export const FastAnswersModal = memo(() => {
  const { closeModal } = useModalActions();
  const { updateOperatorFastAnswers, fastAnswers } = useOperatorFastAnswers();

  const submitForm = useCallback(
    async (payload: FastAnswersList) => {
      try {
        await updateOperatorFastAnswers(payload);

        closeModal();

        toast.showMessage('Templates updated.');
      } catch (error) {
        // the error is handled inside the updateOperatorFastAnswers func.
        // Here we just reflect on it to show the error toast
        toast.showError('Templates update failed.');
      }
    },
    [closeModal, updateOperatorFastAnswers],
  );

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.title}>Templates</div>
        <div className={styles.subtitle}>
          Use templates for sending messages faster. Enter %Name% to add name in your message
          template. For example: Hello %Name%! Do you want to chat?
        </div>
      </div>
      <div className="formWrapper">
        {fastAnswers && (
          <FastAnswerModalForm
            fastAnswers={fastAnswers}
            onSubmit={submitForm}
            onCancel={closeModal}
          />
        )}
      </div>
    </div>
  );
});
