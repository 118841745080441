import React, { memo, useCallback, useMemo } from 'react';

import { useOperatorFastAnswers } from 'modules/domain/fast-answers/hooks';
import { FastAnswer } from 'modules/domain/fast-answers/model';
import { useShortcutListener } from 'modules/components/common/hooks';
import { useCurrentDialogUsersIds } from 'modules/domain/dialog/hooks';
import { useUserField } from 'modules/domain/users/hooks';
import { ModalNames } from 'modules/domain/modals/model';
import { useModalActions } from 'modules/domain/modals/hooks';
import { replaceByMask } from 'modules/utils';
import { useChatInputActions } from 'modules/domain/chat-input/hooks';

import { FastAnswersAddButton } from './add-button';
import { FastAnswersSlider } from './slider';
import styles from './index.module.scss';

export const FastAnswers = memo(() => {
  const { fastAnswers } = useOperatorFastAnswers();
  const { attendeeId } = useCurrentDialogUsersIds();
  const attendeeName = useUserField(attendeeId, 'name');
  const { setFastAnswerSelected } = useChatInputActions();
  const { openModal } = useModalActions();

  const handleFastAnswerSelect = useCallback(
    (fastAnswer: FastAnswer) => {
      const message = replaceByMask(fastAnswer.phrase, '%name%', attendeeName || '');
      setFastAnswerSelected(message, fastAnswer);
    },
    [setFastAnswerSelected, attendeeName],
  );

  const handleShortcutPress = useCallback(
    (keyNumber: number) => {
      const foundFastAnswer = fastAnswers?.find(item => keyNumber === item.shortcutDigit);

      if (foundFastAnswer) {
        handleFastAnswerSelect(foundFastAnswer);
      }
    },
    [fastAnswers, handleFastAnswerSelect],
  );

  useShortcutListener(handleShortcutPress, 'ctrlKey');

  const handleAddBtnClick = () => {
    openModal(ModalNames.FastAnswers);
  };

  const filledFastAnswers = useMemo(() => {
    return fastAnswers?.filter(fastAnswer => fastAnswer.phrase.length);
  }, [fastAnswers]);

  const hasFastAnswers = !!filledFastAnswers?.length;

  return (
    <div className={styles.fastAnswers}>
      <FastAnswersAddButton
        variant={hasFastAnswers ? 'default' : 'extended'}
        onClick={handleAddBtnClick}
      />
      {hasFastAnswers && (
        <FastAnswersSlider
          fastAnswers={filledFastAnswers}
          onFastAnswerClick={handleFastAnswerSelect}
        />
      )}
    </div>
  );
});
