import { useEffect, useState } from 'react';

import { useChatInputText, useCopilotLastUsedData } from 'modules/domain/chat-input/hooks';

export const useCopilotOptionSelected = () => {
  const [optionSelected, setOptionSelected] = useState(false);

  const copilotLastUsedData = useCopilotLastUsedData();
  const inputText = useChatInputText();

  useEffect(() => {
    if (copilotLastUsedData?.options?.some(option => option.text === inputText)) {
      setOptionSelected(true);
    }
  }, [copilotLastUsedData, inputText]);

  useEffect(() => {
    if (optionSelected) {
      setOptionSelected(false);
    }
  }, [optionSelected]);

  return optionSelected;
};
