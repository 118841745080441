import { DialogDto, DialogQueueType } from 'modules/api/dto';

import { DialogMessagesAllowed, DialogMessagesInitState } from '../types';

export const mapDialogDtoToMessagesInitState = (dto: DialogDto): DialogMessagesInitState => {
  return {
    unansweredUnpaid: {
      hasMessages: dto.QueueTypeId === DialogQueueType.Blue,
    },
    messagesAllowed:
      dto.QueueTypeId === DialogQueueType.Grey
        ? DialogMessagesAllowed.TextAndAudio
        : DialogMessagesAllowed.All,
  };
};
