import { DialogMessageDto } from 'modules/api/dto';
import { parseMessageReference } from 'modules/utils';

import { AudioMessageContent, DialogMessage, MessageType } from '../types';

export const mapDialogMessageDto = (dto: DialogMessageDto, animatorId: string): DialogMessage => {
  const message: DialogMessage = {
    id: dto.id,
    senderId: dto.sender,
    recipientId: dto.recipient,
    outgoing: dto.sender === animatorId,
    timestamp: dto.timestamp,
    read: !!dto.read,
    tag: dto.tag,
    meta: dto.meta,
    status: dto.status,
    type: MessageType.TEXT,
    content: dto.text,
  };

  if (dto.meta.gift) {
    const giftPath = dto.meta.gift.replace('_', '/');
    const isAnimated = giftPath.includes('animated');

    message.type = MessageType.GIFT;
    message.content = {
      basename: `/gifts/images/${giftPath}.${isAnimated ? 'json' : 'png'}`,
      isAnimated,
    };
  } else if (dto.meta.reference) {
    const { type, basename } = parseMessageReference(dto.meta.reference);

    message.type = type;
    message.content = { basename };

    if (type === MessageType.AUDIO) {
      (message.content as AudioMessageContent).transcription = dto.meta.transcription;
    }
  } else if (dto.meta.localPhotoUri) {
    message.type = MessageType.PHOTO;
    message.content = {
      localFilePath: dto.meta.localPhotoUri,
    };
  } else if (message.meta.icebreakerType) {
    message.type = MessageType.ICEBREAKER;
    message.content = {
      text: message.meta.icebreakerText || '',
      type: message.meta.icebreakerType,
    };
  }

  return message;
};

export const mapDialogMessagesDto = (
  messagesDto: DialogMessageDto[],
  animatorId: string,
): DialogMessage[] => {
  return (
    messagesDto
      // we have to omit all sensitive messages
      .filter(message => !message.meta.sensitive)
      .map(dto => mapDialogMessageDto(dto, animatorId))
  );
};
