import { useCallback } from 'react';

import { parseDialogId } from 'modules/utils';

import { useAudioMessagesApi } from './use-audio-messages-api';
import { useAudioMessagesTemplatesState } from './selectors';
import { useAudioMessagesActions } from './actions';

export const useAudioMessagesTemplates = (dialogId: string) => {
  const audioMessagesTemplates = useAudioMessagesTemplatesState(dialogId);
  const { setAudioMessages, resetAudioMessages } = useAudioMessagesActions();
  const { getAudioMessageTemplates, checkAudioMessagesEnabled } = useAudioMessagesApi();

  const loadAudioMessagesTemplates = useCallback(
    async (reset?: boolean) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      if (reset) {
        resetAudioMessages();
      }

      const enabled = await checkAudioMessagesEnabled(attendeeId);
      if (!enabled) {
        return;
      }

      const audioMessageTemplates = await getAudioMessageTemplates(animatorId, attendeeId);
      setAudioMessages(dialogId, audioMessageTemplates);
    },
    [
      dialogId,
      resetAudioMessages,
      setAudioMessages,
      getAudioMessageTemplates,
      checkAudioMessagesEnabled,
    ],
  );

  return { audioMessagesTemplates, loadAudioMessagesTemplates };
};
