import { audioMessagesModel } from './model';

const { reducer, reducerPath, actions, selectors } = audioMessagesModel;

export const { setAudioMessages, resetAudioMessages } = actions;
export const { getAudioMessagesState } = selectors;
export { reducer, reducerPath as namespace };

export type { AudioMessageTemplate, AudioMessagesModelState } from './types';
export { mapAudioMessageTemplateDto } from './adapter';
