import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Dialog } from 'modules/domain/dialog/model';

import { setDialogGifts } from '../model';

import { useGiftsApi } from './use-gifts-api';
import { useGiftsPriceLimitPoller } from './use-gifts-price-limit-poller';
import { useGiftsPriceLimitChecker } from './use-gifts-price-limit-checker';
import { useGiftsAllowed } from './use-gifts-allowed';

export const useGiftsLoader = (currentDialog: Dialog) => {
  const { id: dialogId, attendeeId } = currentDialog;

  const giftsAllowed = useGiftsAllowed(dialogId);

  const dispatch = useDispatch();
  const { getGifts } = useGiftsApi(dialogId);

  useEffect(() => {
    if (!giftsAllowed) {
      return;
    }

    getGifts(attendeeId).then(gifts => {
      dispatch(setDialogGifts({ dialogId, gifts }));
    });
  }, [dispatch, getGifts, dialogId, attendeeId, giftsAllowed]);

  const checkPriceLimit = useGiftsPriceLimitChecker(dialogId);

  // We need to monitor actual gifts price limit to hide gifts when limit is reached
  useGiftsPriceLimitPoller(checkPriceLimit, giftsAllowed);
};
